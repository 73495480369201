import { inboundProcessApi } from "src/api/inbound-process";
import { paths } from "src/paths";
import { slice } from "src/slices/inbound-process";

const verifyPurchaseOrderHash = (purchaseOrderHash) => async (dispatch) => {
  try {
    dispatch(slice.actions.setPOVerificationLoading(true));

    const response = await inboundProcessApi
      .verifyPurchaseOrderHash(purchaseOrderHash)
      .catch(() => {
        dispatch(slice.actions.setPOIsValid(false));
        dispatch(slice.actions.setPOVerificationLoading(false));
        dispatch(slice.actions.setVerifiedPO(true));
      });

    dispatch(slice.actions.setPOIsValid(response.POIsValid));
    dispatch(slice.actions.setDocumentNumber(response.documentNumber));
    dispatch(slice.actions.setPOVerificationLoading(false));
    dispatch(slice.actions.setVerifiedPO(true));
  } catch (err) {
    console.log(err);
  }
};

const getPurchaseOrder = (po_id) => async (dispatch) => {
  try {
    const response = await inboundProcessApi.getPurchaseOrder(po_id);

    dispatch(slice.actions.setPurchaseOrder(response));
  } catch (err) {
    console.log(err);
  }
};

const getPurchaseOrders = () => async (dispatch) => {
  try {
    const response = await inboundProcessApi.getPurchaseOrders();

    dispatch(slice.actions.setPurchaseOrders(response));
  } catch (err) {
    console.log(err);
  }
};

const getPOItems = (purchaseOrderHash) => async (dispatch) => {
  try {
    dispatch(slice.actions.setPOItemsLoading(true));

    const response = await inboundProcessApi
      .getPOItems(purchaseOrderHash)
      .catch(() => {
        dispatch(slice.actions.setPOItems(false));
        dispatch(slice.actions.setPOItemsLoading(false));
      });

    dispatch(slice.actions.setPOItems(response));
    dispatch(slice.actions.setPOItemsLoading(false));
  } catch (err) {
    console.log(err);
  }
};

const getInboundRequest = (inboundRequestId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setInboundRequestLoading(true));

    const response = await inboundProcessApi.getInboundRequest(
      inboundRequestId
    );

    dispatch(slice.actions.setInboundRequest(response));
    dispatch(slice.actions.setInboundRequestLoading(false));
  } catch (err) {
    dispatch(slice.actions.setInboundRequestLoading(false));
    console.log(err);
  }
};

const getInboundRequests = () => async (dispatch) => {
  try {
    const response = await inboundProcessApi.getInboundRequests();

    dispatch(slice.actions.setInboundRequests(response));
  } catch (err) {
    console.log(err);
  }
};

const getInboundRequestsOfBrand = (brandName) => async (dispatch) => {
  try {
    const response = await inboundProcessApi.getInboundRequestsOfBrand(
      brandName
    );

    dispatch(slice.actions.setInboundRequests(response));
  } catch (err) {
    console.log(err);
  }
};

const createInboundRequest = (formData) => async (dispatch) => {
  try {
    dispatch(slice.actions.setSubmitInboundRequestError(false));
    dispatch(slice.actions.setSubmitCurrencyError(false));
    dispatch(slice.actions.setSubmitInboundRequestLoading(true));

    await inboundProcessApi.createInboundRequest(formData);

    dispatch(slice.actions.setSubmitInboundRequestLoading(false));
    window.location.href = paths.dashboard.inbound.index;
  } catch (err) {
    if (err.response.data.error.message === "currency_value_mismatch") {
      dispatch(slice.actions.setSubmitCurrencyError(true));
    } else {
      dispatch(slice.actions.setSubmitInboundRequestError(true));
    }
    dispatch(slice.actions.setSubmitInboundRequestLoading(false));
  }
};

const editInboundRequest = (formData) => async (dispatch) => {
  try {
    dispatch(slice.actions.setSubmitInboundRequestError(false));
    dispatch(slice.actions.setSubmitInboundRequestLoading(true));

    await inboundProcessApi.editInboundRequest(formData);

    dispatch(slice.actions.setSubmitInboundRequestLoading(false));
    window.location.href = paths.dashboard.inbound.index;
  } catch (err) {
    console.log(err);
    dispatch(slice.actions.setSubmitInboundRequestError(true));
    dispatch(slice.actions.setSubmitInboundRequestLoading(false));
  }
};

const submitInboundRequest = (id, userId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setInboundRequestStatusChangeSubmitLoading(true));

    await inboundProcessApi.submitInboundRequest(id, userId);

    dispatch(slice.actions.setInboundRequestStatusChangeSubmitLoading(false));

    window.location.href = paths.dashboard.inbound.index;
  } catch (err) {
    console.log(err);
  }
};

const draftInboundRequest = (id, userId, notes) => async (dispatch) => {
  try {
    dispatch(slice.actions.setInboundRequestStatusChangeDraftLoading(true));

    await inboundProcessApi.draftInboundRequest(id, userId, notes);

    dispatch(slice.actions.setInboundRequestStatusChangeDraftLoading(false));
    window.location.href = paths.dashboard.inbound.index;
  } catch (err) {
    console.log(err);
  }
};

const approveInboundRequest = (id, userId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setInboundRequestStatusChangeApproveLoading(true));

    await inboundProcessApi.approveInboundRequest(id, userId);

    dispatch(slice.actions.setInboundRequestStatusChangeApproveLoading(false));
    window.location.href = paths.dashboard.inbound.index;
  } catch (err) {
    console.log(err);
  }
};

export const thunks = {
  verifyPurchaseOrderHash,
  getPurchaseOrder,
  getPurchaseOrders,
  getPOItems,
  getInboundRequest,
  getInboundRequests,
  getInboundRequestsOfBrand,
  createInboundRequest,
  editInboundRequest,
  submitInboundRequest,
  draftInboundRequest,
  approveInboundRequest,
};
