import axios from "axios";
import { wait } from "src/utils/wait";

const deployEnvironment = require("../../deployEnvironment.js");
const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

class InboundProcessAPI {
  async verifyPurchaseOrderHash(purchaseOrderHash) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/inbound-request/verifyPurchaseOrderHash`,
      { po_id: purchaseOrderHash },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const POIsValid = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!POIsValid) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(POIsValid);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getPurchaseOrder(po_id) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/inbound-request/getPurchaseOrder`,
      { po_id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const purchaseOrder = response.data.purchaseOrder;

    return new Promise((resolve, reject) => {
      try {
        if (!purchaseOrder) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(purchaseOrder);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getPurchaseOrders() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.get(
      `${API}/api/inbound-request/getPurchaseOrders`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const purchaseOrders = response.data.purchaseOrders;

    return new Promise((resolve, reject) => {
      try {
        if (!purchaseOrders) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(purchaseOrders);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getPOItems(purchaseOrderHash) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/inbound-request/getPOItems`,
      { po_id: purchaseOrderHash },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const POItems = response.data.POItems;

    return new Promise((resolve, reject) => {
      try {
        if (!POItems) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(POItems);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getInboundRequest(inboundRequestId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/inbound-request/getInboundRequest`,
      { inboundRequestId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const inboundRequest = response.data.inboundRequest;

    return new Promise((resolve, reject) => {
      try {
        if (!inboundRequest) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(inboundRequest);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getInboundRequests() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.get(
      `${API}/api/inbound-request/getInboundRequests`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const inboundRequests = response.data.inboundRequests;

    return new Promise((resolve, reject) => {
      try {
        if (!inboundRequests) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(inboundRequests);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getInboundRequestsOfBrand(brandName) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/inbound-request/getInboundRequestsOfBrand`,
      { brandName },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const inboundRequests = response.data.inboundRequests;

    return new Promise((resolve, reject) => {
      try {
        if (!inboundRequests) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(inboundRequests);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async createInboundRequest(formData) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/inbound-request/createInboundRequest`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async editInboundRequest(formData) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/inbound-request/editInboundRequest`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async submitInboundRequest(id, userId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/inbound-request/submitInboundRequest`,
      { inboundRequestId: id, userId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async draftInboundRequest(id, userId, notes) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/inbound-request/draftInboundRequest`,
      { inboundRequestId: id, userId, notes },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async approveInboundRequest(id, userId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/inbound-request/approveInboundRequest`,
      { inboundRequestId: id, userId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}

export const inboundProcessApi = new InboundProcessAPI();
