import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chip, SvgIcon, Box, Button } from "@mui/material";
// import AlignLeft02Icon from 'src/icons/untitled-ui/duocolor/align-left-02';
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import PaymentsIcon from "@mui/icons-material/Payments";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { useSettings } from "src/hooks/use-settings";
// import Settings04Icon from '@untitled-ui/icons-react/build/esm/Settings04';
// import { Settings } from '@mui/icons-material';
import { useAuth } from "src/hooks/use-auth";
// User Group Types
import { groupTypes } from "src/guards/role-based-guard";
// User Permissions
import { priceSetupTomorrowCrewPermissions } from "src/guards/role-based-guard";
import { linesheetsTomorrowCrewPermissions } from "src/guards/role-based-guard";
import { priceManagementTomorrowCrewPermissions } from "src/guards/role-based-guard";
import { productAttributesTomorrowCrewPermissions } from "src/guards/role-based-guard";
import { purchaseOrdersTomorrowCrewPermissions } from "src/guards/role-based-guard";
import { clientManagementTomorrowCrewPermission } from "src/guards/role-based-guard";
import { linesheetSettingsTomorrowCrewPermissions } from "src/guards/role-based-guard";
import { bookingSystemTomorrowCrewPermissions } from "src/guards/role-based-guard";
import { inboundProcessPermission } from "src/guards/role-based-guard";
import { assetsPermission } from "src/guards/role-based-guard";

export const useSections = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { handleDrawerOpen } = useSettings();

  // User
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // Dashboard Menu
  const dashboardMenuItems = [];

  const linesheetsMenuItem = {
    title: t(tokens.nav.linesheets),
    path: paths.dashboard.linesheets.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <ListAltIcon />
      </SvgIcon>
    ),
  };

  const priceListsMenuItem = {
    title: "Price Management",
    path: paths.dashboard.priceManagement.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <PaymentsIcon />
      </SvgIcon>
    ),
  };

  const productAttributesMenuItem = {
    title: "Product Attributes",
    path: paths.dashboard.productAttributes.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <SummarizeIcon />
      </SvgIcon>
    ),
  };

  const purchaseOrdersMenuItem = {
    title: "Purchase Orders",
    path: paths.dashboard.purchaseOrders.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <SummarizeIcon />
      </SvgIcon>
    ),
  };

  const priceSetupMenuItem = {
    title: "Price Setup",
    path: paths.dashboard.priceSetup.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <SettingsIcon />
      </SvgIcon>
    ),
  };

  const linesheetSettingsItem = {
    title: "Linesheet Settings",
    path: paths.dashboard.linesheetSettings.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <SettingsIcon />
      </SvgIcon>
    ),
  };

  const clientManagementItem = {
    title: "Client Management",
    path: paths.dashboard.clientManagement.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <SettingsIcon />
      </SvgIcon>
    ),
  };

  const bookingSystemItem = {
    title: "Booking System",
    path: paths.dashboard.bookingSystem.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <SettingsIcon />
      </SvgIcon>
    ),
  };

  const assetsItem = {
    title: "Assets",
    path: paths.dashboard.assets.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <SettingsIcon />
      </SvgIcon>
    ),
  };

  const inboundItem = {
    title: "Inbound",
    path: paths.dashboard.inbound.index,
    icon: (
      <SvgIcon fontSize="medium" sx={{ margin: "0 20px 0 20px" }}>
        <SettingsIcon />
      </SvgIcon>
    ),
  };

  // Manage Menu Items based on User Group and Permissions

  //
  // Linesheets
  //

  // Brand
  if (userGroup === groupTypes.brand) {
    dashboardMenuItems.push(linesheetsMenuItem);
  }

  // Tomorrow Crew
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(linesheetsTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasPermission) {
      dashboardMenuItems.push(linesheetsMenuItem);
    }
  }

  //
  // Price Management
  //

  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(priceManagementTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasPermission) {
      dashboardMenuItems.push(priceListsMenuItem);
    }
  }

  //
  // Purchase Orders
  //

  // Brand
  if (userGroup === groupTypes.brand) {
    dashboardMenuItems.push(purchaseOrdersMenuItem);
  }

  // Tomorrow Crew
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(purchaseOrdersTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasPermission) {
      dashboardMenuItems.push(purchaseOrdersMenuItem);
    }
  }

  //
  // Product Attributes
  //

  // Brand
  if (userGroup === groupTypes.brand) {
    dashboardMenuItems.push(productAttributesMenuItem);
  }

  // Tomorrow Crew
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(productAttributesTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasPermission) {
      dashboardMenuItems.push(productAttributesMenuItem);
    }
  }

  //
  // Price Setup
  //

  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(priceSetupTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasPermission) {
      dashboardMenuItems.push(priceSetupMenuItem);
    }
  }

  //
  // Inbound
  //

  if (userGroup === groupTypes.tomorrowCrew || userGroup === groupTypes.brand) {
    // Inbound
    const hasInboundPermission = userPermissions.some((obj) =>
      Object.entries(inboundProcessPermission).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasInboundPermission || userGroup === groupTypes.brand) {
      dashboardMenuItems.push(inboundItem);
    }
  }

  //
  // Tomorrow Crew
  //

  if (userGroup === groupTypes.tomorrowCrew) {
    // Client management
    const hasClientManagementPermission = userPermissions.some((obj) =>
      Object.entries(clientManagementTomorrowCrewPermission).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasClientManagementPermission) {
      dashboardMenuItems.push(clientManagementItem);
    }

    // Booking system
    const hasBookingSystemPermission = userPermissions.some((obj) =>
      Object.entries(bookingSystemTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasBookingSystemPermission) {
      dashboardMenuItems.push(bookingSystemItem);
    }

    // Linesheet settings
    const hasLinesheetSettingsPermission = userPermissions.some((obj) =>
      Object.entries(linesheetSettingsTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasLinesheetSettingsPermission) {
      dashboardMenuItems.push(linesheetSettingsItem);
    }

    // Assets
    const hasAssetsPermission = userPermissions.some((obj) =>
      Object.entries(assetsPermission).some(
        ([key, value]) => obj["key"] === value
      )
    );

    if (hasAssetsPermission) {
      dashboardMenuItems.push(assetsItem);
    }
  }

  return useMemo(() => {
    return [
      {
        subheader: t(tokens.nav.pages),
        items: dashboardMenuItems,
      },
      // {
      //   subheader: t(tokens.nav.concepts),
      //   items: [
      //     {
      //       title: t(tokens.nav.customers),
      //       path: paths.dashboard.customers.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Users03Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.customers.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.customers.details
      //         },
      //         {
      //           title: t(tokens.nav.edit),
      //           path: paths.dashboard.customers.edit
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.productList),
      //       path: paths.dashboard.products.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <ShoppingBag03Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.products.index
      //         },
      //         {
      //           title: t(tokens.nav.create),
      //           path: paths.dashboard.products.create
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.orderList),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <ShoppingCart01Icon />
      //         </SvgIcon>
      //       ),
      //       path: paths.dashboard.orders.index,
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.orders.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.orders.details
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.invoiceList),
      //       path: paths.dashboard.invoices.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <ReceiptCheckIcon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.invoices.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.invoices.details
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.logistics),
      //       path: paths.dashboard.logistics.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Truck01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.dashboard),
      //           path: paths.dashboard.logistics.index
      //         },
      //         {
      //           title: t(tokens.nav.fleet),
      //           path: paths.dashboard.logistics.fleet
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.academy),
      //       path: paths.dashboard.academy.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <GraduationHat01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.dashboard),
      //           path: paths.dashboard.academy.index
      //         },
      //         {
      //           title: t(tokens.nav.course),
      //           path: paths.dashboard.academy.courseDetails
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.jobList),
      //       path: paths.dashboard.jobs.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Building04Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.browse),
      //           path: paths.dashboard.jobs.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.jobs.companies.details
      //         },
      //         {
      //           title: t(tokens.nav.create),
      //           path: paths.dashboard.jobs.create
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.socialMedia),
      //       path: paths.dashboard.social.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Share07Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.profile),
      //           path: paths.dashboard.social.profile
      //         },
      //         {
      //           title: t(tokens.nav.feed),
      //           path: paths.dashboard.social.feed
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.blog),
      //       path: paths.dashboard.blog.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <LayoutAlt02Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.postList),
      //           path: paths.dashboard.blog.index
      //         },
      //         {
      //           title: t(tokens.nav.postDetails),
      //           path: paths.dashboard.blog.postDetails
      //         },
      //         {
      //           title: t(tokens.nav.postCreate),
      //           path: paths.dashboard.blog.postCreate
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.fileManager),
      //       path: paths.dashboard.fileManager,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Upload04Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.kanban),
      //       path: paths.dashboard.kanban,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CheckDone01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.mail),
      //       path: paths.dashboard.mail,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Mail03Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.chat),
      //       path: paths.dashboard.chat,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <MessageChatSquareIcon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.calendar),
      //       path: paths.dashboard.calendar,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CalendarIcon />
      //         </SvgIcon>
      //       )
      //     }
      //   ]
      // },
      // {
      //   subheader: t(tokens.nav.pages),
      //   items: [
      //     {
      //       title: t(tokens.nav.auth),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Lock01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.login),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.login.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.login.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.register),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.register.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.register.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.forgotPassword),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.forgotPassword.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.forgotPassword.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.resetPassword),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.resetPassword.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.resetPassword.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.verifyCode),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.verifyCode.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.verifyCode.modern
      //             }
      //           ]
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.pricing),
      //       path: paths.pricing,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CreditCard01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.checkout),
      //       path: paths.checkout,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <LogOut01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.contact),
      //       path: paths.contact,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Mail04Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.error),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <XSquareIcon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: '401',
      //           path: paths['401']
      //         },
      //         {
      //           title: '404',
      //           path: paths['404']
      //         },
      //         {
      //           title: '500',
      //           path: paths['500']
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   subheader: 'Misc',
      //   items: [
      //     {
      //       title: 'Level 0',
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <AlignLeft02Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: 'Level 1a',
      //           items: [
      //             {
      //               title: 'Level 2a',
      //               items: [
      //                 {
      //                   title: 'Level 3a'
      //                 },
      //                 {
      //                   title: 'Level 3b',
      //                   disabled: true
      //                 }
      //               ]
      //             },
      //             {
      //               title: 'Level 2b'
      //             }
      //           ]
      //         },
      //         {
      //           title: 'Level 1b'
      //         }
      //       ]
      //     },
      //     {
      //       title: 'Disabled',
      //       disabled: true,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <XSquareIcon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: 'Label',
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <File01Icon />
      //         </SvgIcon>
      //       ),
      //       label: (
      //         <Chip
      //           color="primary"
      //           label="New"
      //           size="small"
      //         />
      //       )
      //     },
      //     {
      //       title: 'Blank',
      //       path: paths.dashboard.blank,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <File01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: 'External Link',
      //       path: 'https://devias.io',
      //       external: true,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <File01Icon />
      //         </SvgIcon>
      //       )
      //     }
      //   ]
      // }
    ];
  }, [t]);
};

// items: [
// {
//   title: t(tokens.nav.overview),
//   path: paths.dashboard.index,
//   icon: (
//     <SvgIcon fontSize="small">
//       <HomeSmileIcon />
//     </SvgIcon>
//   )
// },
// {
//   title: t(tokens.nav.settings),
//   icon: (
//     <SvgIcon fontSize="small">
//       <Settings />
//     </SvgIcon>
//   )
// },
// {
//   title: t(tokens.nav.ecommerce),
//   path: paths.dashboard.ecommerce,
//   icon: (
//     <SvgIcon fontSize="small">
//       <LineChartUp04Icon />
//     </SvgIcon>
//   )
// },
// {
//   title: t(tokens.nav.crypto),
//   path: paths.dashboard.crypto,
//   icon: (
//     <SvgIcon fontSize="small">
//       <CurrencyBitcoinCircleIcon />
//     </SvgIcon>
//   ),
//   label: (
//     <Chip
//       color="primary"
//       label="New"
//       size="small"
//     />
//   )
// },
// {
//   title: t(tokens.nav.account),
//   path: paths.dashboard.account,
//   icon: (
//     <SvgIcon fontSize="small">
//       <HomeSmileIcon />
//     </SvgIcon>
//   )
// }
// ]
