import moment from "moment";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Box, Chip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { paths } from "src/paths";
import { useRouter } from "src/hooks/use-router";
// Icons
import TableRowsIcon from "@mui/icons-material/TableRows";
import ManageProductsIcon from "src/icons/tmw-ui/Tomorrow-Portal-Manage-Products-Symbol.png";
import EditLinesheetIcon from "src/icons/tmw-ui/Tomorrow-Portal-Edit-Linesheets-Symbol.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// User Group Types
import { groupTypes } from "src/guards/role-based-guard";
import { CustomTooltip } from "src/components/inputs";

export const getInboundStatusType = {
  draft: "Draft",
  pending: "Pending",
  approved: "Approved",
  released: "Released",
  missingGoods: "Missing Goods",
  closed: "Closed",
};

export const dataGridStyle = {
  fontFamily: "tmwCustomFont",
  "& .MuiDataGrid-columnHeaders": {
    borderTop: "1px solid #E6E6E6",
    borderRadius: 0,
    borderBottom: "1px solid #E6E6E6",
  },
  "& .MuiInputBase-input": {
    fontSize: 12,
    fontWeight: 500,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
  },
  "& .MuiDataGrid-columnHeader--emptyGroup": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:not(:first-of-type)": {
    borderLeft: "1px solid #E6E6E6",
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    borderLeft: "1px solid #E6E6E6",
    letterSpacing: 1,
    fontSize: 12,
    fontWeight: 400,
  },
  "& .MuiDataGrid-cell:last-child": {
    borderRight: "1px solid #E6E6E6",
  },
  "& .MuiDataGrid-row:not(:last-child)": {
    paddingTop: "1px",
    borderBottom: "1px solid #E6E6E6",
  },
  "& .MuiDataGrid-columnSeparator": {
    opacity: "0 !important",
  },
  "& .first-header-style": {
    borderTop: "1px solid #E6E6E6",
    borderLeft: "1px solid #E6E6E6 !important",
    outline: "none !important",
  },
  "& .last-header-style": {
    borderTop: "1px solid #E6E6E6",
    borderRight: "1px solid #E6E6E6 !important",
  },
  "& .header-style": {
    borderTop: "1px solid #E6E6E6",
    letterSpacing: 2,
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  "& .actions": {
    borderLeft: "1px solid #E6E6E6",
    display: "flex",
    justifyContent: "space-around",
  },
  "& .MuiDataGrid-footerContainer": {
    letterSpacing: 2,
    textTransform: "uppercase",
  },
  "& .MuiTablePagination-selectLabel": {
    fontSize: 11,
    letterSpacing: 1,
  },
  "& .MuiTablePagination-displayedRows": {
    fontSize: 11,
    letterSpacing: 1,
  },
  "& .MuiTablePagination-select": {
    fontSize: 11,
    letterSpacing: 1,
  },
  "& .MuiDataGrid-virtualScroller": {
    borderBottom: "1px solid #E6E6E6",
    letterSpacing: 2,
    fontSize: 10,
    fontWeight: 600,
    minHeight: "50px",
  },
};

export const columnsDefinition = (userGroup) => {
  const { t } = useTranslation();
  const router = useRouter();

  const tomorrowCrewActionButtons = (id, row) => [
    <CustomTooltip title={`${row.status}`} key={"inbound-status-tooltip-" + id}>
      {statusCellComponent(row.status)}
    </CustomTooltip>,
    // <CustomTooltip
    //   title={"View Item Lines"}
    //   key={"view-item-lines-tooltip-" + id}
    // >
    //   <GridActionsCellItem
    //     icon={
    //       <img
    //         style={{
    //           width: "15px",
    //         }}
    //         src={ManageProductsIcon}
    //       />
    //     }
    //     label="View Item Lines"
    //     className="textPrimary"
    //     color="inherit"
    //     key={"viewItemLines-" + id}
    //     onClick={() =>
    //       router.push(paths.dashboard.inbound.items + id + "/details")
    //     }
    //   />
    // </CustomTooltip>,
    <CustomTooltip
      title={
        row.status === getInboundStatusType.approved ||
        row.status === getInboundStatusType.closed
          ? "View Inbound Request"
          : "Update Inbound Request"
      }
      key={"revert-status-tooltip-" + id}
      style={{ marginLeft: "5px" }}
    >
      <GridActionsCellItem
        icon={<img style={{ width: "15px" }} src={EditLinesheetIcon} />}
        label={
          row.status === getInboundStatusType.approved ||
          row.status === getInboundStatusType.closed
            ? "View Request"
            : "Edit Request"
        }
        className="textPrimary"
        color="inherit"
        key={"revertStatus-" + id}
        onClick={() =>
          (window.location.href = paths.dashboard.inbound.edit + row.id)
        }
      />
    </CustomTooltip>,
  ];

  // Handle Action Buttons based on User Group
  const actionButtons = (id, row) => {
    // if (userGroup === groupTypes.brand) {
    //   return brandActionButtons(id, row);
    // }

    // if (userGroup === groupTypes.tomorrowCrew) {
    return tomorrowCrewActionButtons(id, row);
    // }
  };

  const tomorrowCrewColumns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Row actions",
      renderHeader: () => (
        <TableRowsIcon
          style={{
            transform: "rotate(90deg) scale(.95, 1)",
          }}
        />
      ),
      headerClassName: "first-header-style header-style",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return actionButtons(id, row);
      },
    },
    {
      field: "documentNumber",
      headerName: "PO Document Number",
      headerClassName: "header-style",
      flex: 1.25,
      editable: false,
      headerAlign: "center",
      valueGetter: (params) => params.row.purchase_order?.documentNumber || "",
    },
    {
      field: "inboundShippingNumber",
      headerName: "Inbound Shipping Number",
      headerClassName: "header-style",
      flex: 1.4,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "trackingNumber",
      headerName: "Tracking Number",
      headerClassName: "header-style",
      flex: 1,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "brandName",
      headerName: "Brand",
      headerClassName: "header-style",
      flex: 1.2,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "season",
      headerName: "Season",
      headerClassName: "header-style",
      flex: 1,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "author",
      headerName: "Author",
      headerClassName: "header-style",
      flex: 1,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "Created",
      headerClassName: "header-style",
      flex: 1,
      editable: false,
      headerAlign: "center",
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "updatedAt",
      headerName: "Last Update",
      headerClassName: "header-style last-header-style",
      flex: 1,
      editable: false,
      headerAlign: "center",
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY HH:mm:ss"),
    },
  ];

  // Handle Columns based on User Group
  const columnsBasedOnUserGroup = () => {
    // if (userGroup === groupTypes.brand) {
    //   return brandColumns;
    // }

    // if (userGroup === groupTypes.tomorrowCrew) {
    return tomorrowCrewColumns;
    // }
  };

  const columns = columnsBasedOnUserGroup();

  return columns;
};

// Status Cell custom Component
export const statusCellComponent = (status) => {
  return typeof status === "string" ? (
    <Box style={{ margin: "0 auto" }}>
      <Chip
        style={{
          height: "18px",
          width: "18px",
          borderRadius: "50%",
          marginRight: "10px",
          marginLeft: "5px",
          background:
            status === getInboundStatusType.draft
              ? "#F5911E"
              : status === getInboundStatusType.pending
              ? "#FF0000"
              : status === getInboundStatusType.released
              ? "#54C3EA"
              : status === getInboundStatusType.missingGoods
              ? "#785121"
              : status === getInboundStatusType.approved
              ? "#39B349"
              : status === getInboundStatusType.closed
              ? "black"
              : "grey",
        }}
      />
    </Box>
  ) : (
    <Chip
      style={{
        height: "18px",
        width: "18px",
        borderRadius: "50%",
        marginRight: "10px",
        marginLeft: "5px",
      }}
      color={"default"}
    />
  );
};
