import { createSlice } from "@reduxjs/toolkit";

const inboundProcessState = {
  POIsValid: false,
  POVerificationLoading: false,
  verifiedPO: false,
  purchaseOrder: null,
  purchaseOrders: [],
  POItems: [],
  POItemsLoading: false,
  inboundRequest: null,
  inboundRequestLoading: false,
  inboundRequests: [],
  submitInboundRequestLoading: false,
  submitInboundRequestError: false,
  documentNumber: "",
  submitCurrencyError: false,
  inboundRequestStatusChangeSubmitLoading: false,
  inboundRequestStatusChangeDraftLoading: false,
  inboundRequestStatusChangeApproveLoading: false,
};

const reducers = {
  setPOIsValid(state, action) {
    state.POIsValid = action.payload;
  },
  setPOVerificationLoading(state, action) {
    state.POVerificationLoading = action.payload;
  },
  setVerifiedPO(state, action) {
    state.verifiedPO = action.payload;
  },
  setPurchaseOrder(state, action) {
    state.purchaseOrder = action.payload;
  },
  setPurchaseOrders(state, action) {
    state.purchaseOrders = action.payload;
  },
  setPOItems(state, action) {
    state.POItems = action.payload;
  },
  setPOItemsLoading(state, action) {
    state.POItemsLoading = action.payload;
  },
  setInboundRequest(state, action) {
    state.inboundRequest = action.payload;
  },
  setInboundRequestLoading(state, action) {
    state.inboundRequestLoading = action.payload;
  },
  setInboundRequests(state, action) {
    state.inboundRequests = action.payload;
  },
  setSubmitInboundRequestLoading(state, action) {
    state.submitInboundRequestLoading = action.payload;
  },
  setDocumentNumber(state, action) {
    state.documentNumber = action.payload;
  },
  setSubmitInboundRequestError(state, action) {
    state.submitInboundRequestError = action.payload;
  },
  setSubmitCurrencyError(state, action) {
    state.submitCurrencyError = action.payload;
  },
  setInboundRequestStatusChangeSubmitLoading(state, action) {
    state.inboundRequestStatusChangeSubmitLoading = action.payload;
  },
  setInboundRequestStatusChangeDraftLoading(state, action) {
    state.inboundRequestStatusChangeDraftLoading = action.payload;
  },
  setInboundRequestStatusChangeApproveLoading(state, action) {
    state.inboundRequestStatusChangeApproveLoading = action.payload;
  },
};

export const slice = createSlice({
  name: "inboundProcess",
  initialState: inboundProcessState,
  reducers,
});

export const { reducer } = slice;
