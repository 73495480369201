export const paths = {
  index: "/",
  checkout: "/checkout",
  contact: "/contact",
  pricing: "/pricing",
  auth: {
    jwt: {
      login: "/",
      register: "/register",
      createPassword: "/create-password",
      forgotPassword: "/auth/jwt/forgot-password",
      changePassword: "/auth/jwt/change-password/:token",
    },
  },
  authDemo: {
    forgotPassword: {
      classic: "/auth-demo/forgot-password/classic",
      modern: "/auth-demo/forgot-password/modern",
    },
    login: {
      classic: "/auth-demo/login/classic",
      modern: "/auth-demo/login/modern",
    },
    register: {
      classic: "/auth-demo/register/classic",
      modern: "/auth-demo/register/modern",
    },
    resetPassword: {
      classic: "/auth-demo/reset-password/classic",
      modern: "/auth-demo/reset-password/modern",
    },
    verifyCode: {
      classic: "/auth-demo/verify-code/classic",
      modern: "/auth-demo/verify-code/modern",
    },
  },
  dashboard: {
    index: "/dashboard",
    academy: {
      index: "/dashboard/academy",
      courseDetails: "/dashboard/academy/courses/:courseId",
    },
    account: "/dashboard/account",
    linesheets: {
      index: "/dashboard/linesheets",
      createLinesheet: "/dashboard/linesheets/create",
      editLinesheet: "/dashboard/linesheets/edit/",
      manageLinesheetProducts:
        "/dashboard/linesheets/:linesheetId/manage-products",
      createLinesheetProduct:
        "/dashboard/linesheets/:linesheetId/create-product",
    },
    priceManagement: {
      index: "/dashboard/price-management",
      lineModification: "/dashboard/price-management/:linesheetId/prices",
    },
    productAttributes: {
      index: "/dashboard/product-attributes",
    },
    purchaseOrders: {
      index: "/dashboard/purchase-orders",
      viewItems: "/dashboard/purchase-orders/",
    },
    priceSetup: {
      index: "/dashboard/price-setup",
      priceSetupFinanceIndex: "/dashboard/price-setup/finance",
      priceSetupOperationsIndex: "/dashboard/price-setup/operations",
      priceSetupSalesIndex: "/dashboard/price-setup/sales",
    },
    linesheetSettings: {
      index: "/dashboard/linesheet-settings",
      linesheetSettingsBlankTemplate: "/dashboard/price-setup/blank-template",
      linesheetSettingsZedonkMappings: "/dashboard/price-setup/zedonk-mappings",
    },
    clientManagement: {
      index: "/dashboard/client-management",
      companies: {
        index: "/dashboard/client-management/companies",
        clientManagementCompaniesDetails:
          "/dashboard/client-management/companies/:customerId/details",
      },
      contacts: {
        index: "/dashboard/client-management/contacts",
        clientManagementContactsDetails:
          "/dashboard/client-management/contacts/:customerId/details",
      },
    },
    bookingSystem: {
      index: "/dashboard/booking-system",
      allBookings: "/dashboard/booking-system/all-bookings",
      settings: "/dashboard/booking-system/settings",
      skipped: "/dashboard/booking-system/skipped",
    },
    assets: {
      index: "/dashboard/assets",
    },
    inbound: {
      index: "/dashboard/inbound",
      items: "/dashboard/inbound/",
      new: "/dashboard/inbound/new",
      edit: "/dashboard/inbound/edit/",
    },
    blank: "/dashboard/blank",
    blog: {
      index: "/dashboard/blog",
      postDetails: "/dashboard/blog/:postId",
      postCreate: "/dashboard/blog/create",
    },
    calendar: "/dashboard/calendar",
    chat: "/dashboard/chat",
    crypto: "/dashboard/crypto",
    companies: {
      index: "/dashboard/companies",
      details: "/dashboard/companies/:customerId",
      edit: "/dashboard/companies/:customerId/edit",
    },
    ecommerce: "/dashboard/ecommerce",
    fileManager: "/dashboard/file-manager",
    invoices: {
      index: "/dashboard/invoices",
      details: "/dashboard/invoices/:orderId",
    },
    jobs: {
      index: "/dashboard/jobs",
      create: "/dashboard/jobs/create",
      companies: {
        details: "/dashboard/jobs/companies/:companyId",
      },
    },
    kanban: "/dashboard/kanban",
    logistics: {
      index: "/dashboard/logistics",
      fleet: "/dashboard/logistics/fleet",
    },
    mail: "/dashboard/mail",
    orders: {
      index: "/dashboard/orders",
      details: "/dashboard/orders/:orderId",
    },
    products: {
      index: "/dashboard/products",
      create: "/dashboard/products/create",
    },
    social: {
      index: "/dashboard/social",
      profile: "/dashboard/social/profile",
      feed: "/dashboard/social/feed",
    },
  },
  bookingProcess: {
    index: "booking-process",
    calendar: {
      index: "booking-process/:id/calendar",
    },
  },
  customers: {
    index: "/customers",
    dashboard: {
      index: "/customers/dashboard",
    },
    companySelect: {
      index: "/customers/company-select",
    },
    companyForm: {
      index: "/customers/company-form",
    },
  },
  components: {
    index: "/components",
    dataDisplay: {
      detailLists: "/components/data-display/detail-lists",
      tables: "/components/data-display/tables",
      quickStats: "/components/data-display/quick-stats",
    },
    lists: {
      groupedLists: "/components/lists/grouped-lists",
      gridLists: "/components/lists/grid-lists",
    },
    forms: "/components/forms",
    modals: "/components/modals",
    charts: "/components/charts",
    buttons: "/components/buttons",
    typography: "/components/typography",
    colors: "/components/colors",
    inputs: "/components/inputs",
  },
  docs: "https://material-kit-pro-react-docs.devias.io",
  401: "/401",
  404: "/404",
  500: "/500",
};
