import { useEffect, useRef, useState, Fragment } from "react";
import { withInboundProcessGuard } from "src/hocs/with-role-based-guard";
import { paths } from "src/paths";
import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Container,
  Input,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Seo } from "src/components/seo";
import {
  CustomTextField,
  CustomTypography,
  CustomButton,
} from "src/components/inputs";
// Redux Toolkit
import { thunks } from "src/thunks/inbound-process";
import { slice } from "src/slices/inbound-process";
import { useDispatch, useSelector } from "src/store";
import { useAuth } from "src/hooks/use-auth";
import { CloseOutlined, KeyboardBackspace } from "@mui/icons-material";

const AddInboundRequestPage = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const params = useParams();
  const selectPOModalRef = useRef(null);

  // Params
  const purchaseOrderId = params.purchase_order_id;

  const {
    POIsValid,
    POVerificationLoading,
    verifiedPO,
    purchaseOrder,
    purchaseOrders,
    POItems,
    POItemsLoading,
    submitInboundRequestLoading,
    documentNumber,
    submitInboundRequestError,
    submitCurrencyError,
  } = useSelector((state) => state.inboundProcess);

  const [selectedPO, setSelectedPO] = useState(purchaseOrderId || "");
  const [selectPurchaseOrderModalVisible, setSelectPurchaseOrderModalVisible] =
    useState(false);
  const [selectedPOItems, setSelectedPOItems] = useState([]);
  const [
    selectPurchaseOrderItemsModalVisible,
    setSelectPurchaseOrderItemsModalVisible,
  ] = useState(purchaseOrderId ? true : false);
  const [currentModalSection, setCurrentModalSection] = useState("po_items");
  const [quantityValues, setQuantityValues] = useState({});
  //
  // FIELDS
  //
  // const [inboundRequestInformation, setInboundRequestInformation] =
  //   useState("");
  // const [inboundShippingNumber, setInboundShippingNumber] = useState("");
  // const [trackingNumber, setTrackingNumber] = useState("");
  // const [courierInformation, setCourierInformation] = useState("");
  //
  // FILE SELECTS
  //
  // Packing List
  const [packingListFileName, setPackingListFileName] = useState("");
  const [packingListFileData, setPackingListFileData] = useState(null);
  // Invoice
  const [invoiceFileName, setInvoiceFileName] = useState("");
  const [invoiceFileData, setInvoiceFileData] = useState(null);
  // Currency
  const [totalValue, setTotalValue] = useState(0);
  const [invoiceNetTotal, setInvoiceNetTotal] = useState("");
  // // Photos
  // const [photosFileName, setPhotosFileName] = useState("");
  // const [photosFileData, setPhotosFileData] = useState(null);
  // // Credit Note Provided By 3PL
  // const [creditNoteProvidedBy3PLFileName, setCreditNoteProvidedBy3PLFileName] =
  //   useState("");
  // const [creditNoteProvidedBy3PLFileData, setCreditNoteProvidedBy3PLFileData] =
  //   useState(null);
  // // Credit Note Provided By Brand
  // const [
  //   creditNoteProvidedByBrandFileName,
  //   setCreditNoteProvidedByBrandFileName,
  // ] = useState("");
  // const [
  //   creditNoteProvidedByBrandFileData,
  //   setCreditNoteProvidedByBrandFileData,
  // ] = useState(null);

  useEffect(() => {
    if (!purchaseOrderId) {
      setSelectPurchaseOrderModalVisible(true);
      dispatch(thunks.getPurchaseOrders());
    } else {
      dispatch(thunks.verifyPurchaseOrderHash(selectedPO));
    }
  }, [purchaseOrderId]);

  useEffect(() => {
    if (verifiedPO && POIsValid !== undefined) {
      dispatch(slice.actions.setVerifiedPO(false));

      if (!POIsValid) {
        window.location.href = paths.dashboard.inbound.index + "/404";
      }
    }
  }, [verifiedPO]);

  useEffect(() => {
    if (POIsValid) {
      dispatch(thunks.getPOItems(selectedPO));
      dispatch(thunks.getPurchaseOrder(selectedPO));
    }
  }, [POIsValid]);

  useEffect(() => {
    // Initialize each PO item's quantity to 1
    const initialValues = {};

    selectedPOItems.forEach((poItem) => {
      initialValues[poItem.id] = 1;
    });

    setQuantityValues(initialValues);
  }, [selectedPOItems]);

  // useEffect(() => {
  //   let total = 0;

  //   if (POItems?.length) {
  //     POItems.map((POItem) => {
  //       total += POItem.rate * quantityValues[POItem.id];
  //     });
  //   }

  //   setTotalValue(total);
  // }, [quantityValues]);

  const renderSelectPurchaseOrderModal = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(5px)",
          zIndex: 1100,
        }}
      >
        <Box
          ref={selectPOModalRef}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
            width: "50vw",
          }}
        >
          <Container maxWidth="sx">
            <Stack
              elevation={12}
              style={{
                background: "white",
                borderRadius: "25px",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack
                style={{
                  height: "40px",
                  background: "green",
                  borderRadius: "25px 25px 0 0",
                }}
              />
              <Stack style={{ padding: "25px" }}>
                <CustomTypography
                  message="Please select the Purchase Order you want to create an Inbound
                Request for:"
                />
                <Stack
                  style={{
                    width: "100%",
                    alignItems: "center",
                    marginTop: "25px",
                  }}
                >
                  <Autocomplete
                    id="autocomplete-purchaseOrder"
                    name="autocomplete-purchaseOrder"
                    options={purchaseOrders}
                    getOptionLabel={(option) =>
                      `${option.season} | ${option.brandName} | ${option.documentNumber} | ${option.supplier}`
                    }
                    value={
                      purchaseOrders.find(
                        (po) => po.internalId === selectedPO
                      ) || null
                    }
                    onChange={(_, value) =>
                      setSelectedPO(value ? value.internalId : "")
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.internalId}>
                        <div
                          style={{
                            fontFamily: "tmwCustomFont",
                            letterSpacing: 2,
                            fontSize: 11,
                          }}
                        >
                          {option.season} | {option.brandName} |{" "}
                          {option.documentNumber} | {option.supplier}
                        </div>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          "& .MuiInput-underline:before": {
                            borderBottom: "1px solid #E6E6E6",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottom: "2px solid black",
                          },
                          "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                            cursor: "pointer",
                          },
                          "& .MuiInputBase-input": {
                            fontFamily: "tmwCustomFont",
                            paddingLeft: "10px",
                          },
                          padding: "10px 0",
                          width: "100%",
                        }}
                        id="selectedPurchaseOrder"
                        name="selectedPurchaseOrder"
                        fullWidth
                        variant="standard"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </Stack>
                <Stack
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: 10,
                    marginTop: "15px",
                  }}
                >
                  {purchaseOrderId ? (
                    <CustomButton
                      label="Cancel"
                      style={{ width: "100px" }}
                      onClick={() => setSelectPurchaseOrderModalVisible(false)}
                    />
                  ) : (
                    <CustomButton
                      label="Cancel"
                      style={{ width: "100px" }}
                      onClick={() =>
                        (window.location.href = paths.dashboard.inbound.index)
                      }
                    />
                  )}
                  <CustomButton
                    label="Select"
                    style={{ width: "100px" }}
                    disabled={!selectedPO}
                    onClick={() => {
                      window.location.href =
                        paths.dashboard.inbound.index + "/new/" + selectedPO;
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  const handleCreateInboundRequest = () => {
    const currency =
      purchaseOrder?.currency === "EUR"
        ? "€"
        : purchaseOrder?.currency === "GBP"
        ? "£"
        : purchaseOrder?.currency === "USD"
        ? "$"
        : purchaseOrder?.currency === "JPY"
        ? "¥"
        : "";

    const formattedSelectedPOItems = selectedPOItems.map((poItem) => ({
      ...poItem,
      selectedQty: quantityValues[poItem.id],
    }));

    const formData = new FormData();

    // Append fields
    formData.append("author", user.email);
    formData.append("purchaseOrderId", purchaseOrderId);
    formData.append("documentNumber", documentNumber);
    // formData.append("inboundRequestInformation", inboundRequestInformation);
    // formData.append("inboundShippingNumber", inboundShippingNumber);
    // formData.append("trackingNumber", trackingNumber);
    // formData.append("courierInformation", courierInformation);

    // Append PO Items
    formData.append("poItems", JSON.stringify(formattedSelectedPOItems));

    // Append files
    formData.append("packingList", packingListFileData);
    formData.append("invoice", invoiceFileData);

    // Append Invoice Net Total
    formData.append("invoiceNetTotal", invoiceNetTotal);

    dispatch(thunks.createInboundRequest(formData));
  };

  const renderNewInboundRequestButtons = () => {
    const submitButtonDisabled = !(
      packingListFileData &&
      invoiceFileData &&
      invoiceNetTotal
    );

    return (
      <Stack
        style={{
          position: "fixed",
          background: "white",
          right: 0,
          bottom: 0,
          flexDirection: "row",
          gap: 10,
          justifyContent: "flex-end",
          zIndex: 500,
          padding: "25px",
        }}
      >
        <CustomButton
          style={{
            borderColor: submitCurrencyError ? "red" : "",
            background: "white",
          }}
          redButton={submitInboundRequestError}
          label="Submit"
          disabled={submitButtonDisabled}
          onClick={handleCreateInboundRequest}
          loader={
            submitInboundRequestLoading && (
              <CircularProgress
                size={20}
                color="inherit"
                style={{ marginLeft: "10px" }}
              />
            )
          }
        />
        <CustomButton
          style={{ background: "white" }}
          label="Cancel"
          onClick={() => (window.location.href = paths.dashboard.inbound.index)}
        />
      </Stack>
    );
  };

  const renderInputSelect = (
    inputName,
    fileName,
    setFileName,
    fileData,
    setFileData,
    allowedFiles
  ) => {
    const handleFileChange = (event) => {
      const file = event.target.files[0];

      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (allowedFiles.includes(fileExtension)) {
          setFileName(file.name);
          setFileData(file);
        } else {
          alert("Invalid file type! Please upload a PDF file.");

          event.target.value = "";
          setFileName("");
          setFileData(null);
        }
      }
    };

    return (
      <Stack
        style={{
          height: "50px",
          alignItems: "flex-start",
        }}
      >
        <CustomButton
          component="label"
          disabled={!!(fileName || fileData)}
          label={
            <>
              {inputName}
              <Input
                type="file"
                id={inputName}
                accept={allowedFiles.map((ext) => `.${ext}`).join(", ")}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </>
          }
          style={{ width: "fit-content" }}
        />
        {fileName && (
          <Stack
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
              margin: "10px",
              fontFamily: "tmwCustomFont",
              fontSize: 12,
            }}
          >
            {fileName}
            <ButtonBase
              onClick={() => {
                setFileName("");
                setFileData(null);
                dispatch(slice.actions.setSubmitCurrencyError(false));

                // Reset the file input on close
                const fileInput = document.getElementById(inputName);
                if (fileInput) {
                  fileInput.value = "";
                }
              }}
            >
              <CloseOutlined style={{ width: "18px" }} />
            </ButtonBase>
          </Stack>
        )}
      </Stack>
    );
  };

  const renderCurrencySelect = () => {
    const currency =
      purchaseOrder?.currency === "EUR"
        ? "€"
        : purchaseOrder?.currency === "GBP"
        ? "£"
        : purchaseOrder?.currency === "USD"
        ? "$"
        : purchaseOrder?.currency === "JPY"
        ? "¥"
        : "";

    return (
      <>
        <CustomTextField
          label="Invoice Net Total"
          style={{ width: "200px" }}
          value={invoiceNetTotal}
          inputProps={{
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
            },
          }}
          handleChange={(event) => {
            setInvoiceNetTotal(event.target.value);
            dispatch(slice.actions.setSubmitCurrencyError(false));
          }}
          type="number"
        />
        {submitCurrencyError && (
          <Stack
            style={{
              fontFamily: "tmwCustomFont",
              fontSize: 11,
              color: "red",
            }}
          >
            <span>
              Invoice Net Total does not match the calculated total for the
              selected items.
            </span>
            <span>
              Please revise the total and possibly correct also the invoice.
            </span>
          </Stack>
        )}
      </>
    );
  };

  const renderNewInboundRequestContent = () => {
    return (
      <Stack
        style={{
          margin: "15px 0",
          padding: " 0 15px 25px 15px",
          alignItems: "flex-start",
          gap: 25,
        }}
      >
        <Stack
          style={{
            position: "relative",
            width: "100%",
            padding: "25px",
            border: "1px solid green",
          }}
        >
          <Stack style={{ position: "absolute", right: 25 }}>
            <CustomButton
              label="Edit Items"
              style={{ background: "white" }}
              onClick={() => {
                dispatch(slice.actions.setSubmitCurrencyError(false));
                setCurrentModalSection("quantities");
                setSelectPurchaseOrderItemsModalVisible(true);
              }}
            />
          </Stack>
          <CustomTypography
            message="Purchase Order Items:"
            title
            style={{ marginBottom: "35px" }}
          />
          <Stack style={{ width: "100%", alignItems: "center" }}>
            <Stack
              style={{
                display: "flex",
                width: "90%",
                gap: 10,
              }}
            >
              {/* Header */}
              <Stack
                style={{
                  flexDirection: "row",
                  fontWeight: "bold",
                  borderBottom: "1px solid #E6E6E6",
                  paddingBottom: 5,
                }}
              >
                <Stack style={{ flex: 1 }}>Internal ID</Stack>
                <Stack style={{ flex: 2 }}>SKU</Stack>
                <Stack style={{ flex: 1 }}>EAN Code</Stack>
                <Stack style={{ flex: 2 }}>Description</Stack>
                <Stack style={{ flex: 1 }}>Quantity</Stack>
              </Stack>

              {/* POItems */}
              {selectedPOItems.map((POItem, index) => {
                const foundItemsQuantity = quantityValues[POItem.id];

                return (
                  <Stack
                    key={`po-item-${index}`}
                    style={{ flexDirection: "row" }}
                  >
                    <Stack style={{ flex: 1 }}>{POItem.internalId}</Stack>
                    <Stack style={{ flex: 2 }}>{POItem.itemSku}</Stack>
                    <Stack style={{ flex: 1 }}>{POItem.eanCode}</Stack>
                    <Stack style={{ flex: 2 }}>{POItem.description}</Stack>
                    <Stack style={{ flex: 1 }}>{foundItemsQuantity}</Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          style={{
            width: "100%",
            padding: "25px",
            border: "1px solid #E6E6E6",
          }}
        >
          <Stack style={{ width: "100%", alignItems: "center" }}>
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",
                width: "75%",
                maxWidth: "75%",
                gap: 75,
                margin: "30px 0",
              }}
            >
              <Stack
                style={{ gap: 75, justifySelf: "flex-start", width: "100%" }}
              >
                {renderInputSelect(
                  packingListFileData
                    ? "Packing List Document"
                    : "Upload Packing List Document",
                  packingListFileName,
                  setPackingListFileName,
                  packingListFileData,
                  setPackingListFileData,
                  ["pdf"]
                )}
                {renderInputSelect(
                  invoiceFileData ? "Invoice" : "Upload Invoice",
                  invoiceFileName,
                  setInvoiceFileName,
                  invoiceFileData,
                  setInvoiceFileData,
                  ["pdf"]
                )}
              </Stack>
              <Stack
                style={{
                  justifySelf: "flex-end",
                  width: "100%",
                }}
              >
                {renderCurrencySelect()}
              </Stack>
              {/* {renderInputSelect(
                "Upload Photos",
                photosFileName,
                setPhotosFileName,
                photosFileData,
                setPhotosFileData
              )} */}
              {/* {renderInputSelect(
                "Upload Credit Note Provided By 3PL",
                creditNoteProvidedBy3PLFileName,
                setCreditNoteProvidedBy3PLFileName,
                creditNoteProvidedBy3PLFileData,
                setCreditNoteProvidedBy3PLFileData
              )} */}
              {/* {renderInputSelect(
                "Upload Credit Note Provided By Brand",
                creditNoteProvidedByBrandFileName,
                setCreditNoteProvidedByBrandFileName,
                creditNoteProvidedByBrandFileData,
                setCreditNoteProvidedByBrandFileData
              )} */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const renderChoosePOItemsSection = () => {
    return (
      <Stack style={{ gap: 50, padding: "25px" }}>
        <CustomTypography message="Select The Purchase Order Items:" title />
        <Stack style={{ gap: 5, fontFamily: "tmwCustomFont" }}>
          {/* Column Labels */}
          <Stack
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
              fontWeight: "bold",
              padding: "5px 0",
              borderBottom: "1px solid #E6E6E6",
              userSelect: "none",
              paddingLeft: "55px",
              textTransform: "uppercase",
              letterSpacing: 1,
              fontSize: 14,
            }}
          >
            <Stack style={{ flex: 1, textAlign: "left" }}>ID</Stack>
            <Stack style={{ flex: 1, textAlign: "left" }}>Item SKU</Stack>
            <Stack style={{ flex: 1, textAlign: "left" }}>EAN Code</Stack>
            <Stack
              style={{
                flex: 2,
                textAlign: "left",
              }}
            >
              Description
            </Stack>
            <Stack style={{ flex: 1, textAlign: "left" }}>Quantity Left</Stack>
          </Stack>

          {/* Selectable Items */}
          <Stack style={{ maxHeight: "50vh", overflow: "auto" }}>
            {POItems.map((POItem, index) => {
              const isChecked = selectedPOItems.some(
                (item) => item.id === POItem.id
              );
              const disabledItem = POItem.remainingQuantity <= 0;

              return (
                <Stack
                  key={`POItem-${index}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 10,
                    margin: "2px 0",
                    cursor: "pointer",
                    padding: "5px",
                    transition: "background-color 0.2s ease-in-out",
                    userSelect: "none",
                    fontSize: 14,
                    height: "50px",
                  }}
                  sx={{
                    color: disabledItem ? "lightgrey" : "black",
                    "&:hover": !disabledItem && {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                  onClick={() =>
                    !disabledItem &&
                    setSelectedPOItems(
                      (prev) =>
                        isChecked
                          ? prev.filter((item) => item.id !== POItem.id) // Remove object by matching id
                          : [...prev, POItem] // Add the whole POItem object
                    )
                  }
                >
                  <Checkbox
                    className="hover-checkbox"
                    checked={isChecked}
                    onChange={(e) => e.stopPropagation()}
                    style={{
                      color: disabledItem ? "lightgrey" : "black",
                      borderRadius: 0,
                      padding: 0,
                      marginTop: -5,
                    }}
                  />
                  <Stack style={{ flex: 1, textAlign: "left" }}>
                    {POItem.internalId}
                  </Stack>
                  <Stack
                    style={{
                      flex: 1,
                      textAlign: "left",
                    }}
                  >
                    {POItem.itemSku}
                  </Stack>
                  <Stack style={{ flex: 1, textAlign: "left" }}>
                    {POItem.eanCode}
                  </Stack>
                  <Stack
                    style={{
                      flex: 2,
                      textAlign: "left",
                    }}
                  >
                    {POItem.description}
                  </Stack>
                  <Stack
                    style={{
                      flex: 1,
                      textAlign: "left",
                      margin: "0 -50px 0 50px",
                    }}
                  >
                    {POItem.remainingQuantity} / {POItem.qty}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
        <Stack
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 10,
            marginTop: "15px",
          }}
        >
          <CustomButton
            label="Cancel"
            style={{ width: "100px" }}
            onClick={() =>
              (window.location.href = paths.dashboard.inbound.index)
            }
          />
          <CustomButton
            disabled={!selectedPOItems.length}
            label="Next"
            style={{ width: "100px" }}
            onClick={() => setCurrentModalSection("quantities")}
          />
        </Stack>
      </Stack>
    );
  };

  const renderChooseQuantitiesSection = () => {
    const handleQuantityChange = (name, maxValue) => (event) => {
      let value = event.target.value;

      // Allow empty input (so users can delete & retype)
      if (value === "") {
        setQuantityValues((prev) => ({ ...prev, [name]: "" }));
        return;
      }

      let num = Number(value);

      // Allow only numbers within range while typing
      if (!isNaN(num) && num >= 1 && num <= maxValue) {
        setQuantityValues((prev) => ({ ...prev, [name]: num }));
      }
    };

    const handleBlur = (name, maxValue) => () => {
      let num = quantityValues[name];

      // Auto-correct empty values or out-of-range inputs
      if (num === "" || num < 1) num = 1;
      if (num > maxValue) num = maxValue;

      setQuantityValues((prev) => ({ ...prev, [name]: num }));
    };

    return (
      <Stack
        style={{
          gap: 25,
          padding: "25px",
        }}
      >
        <CustomTypography
          message="Select The Purchase Order Items Quantities:"
          title
        />
        <Stack
          style={{
            maxHeight: "50vh",
            overflow: "auto",
            fontFamily: "tmwCustomFont",
          }}
        >
          {/* Header Row */}
          <Stack
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontWeight: "bold",
              padding: "5px 0",
              borderBottom: "1px solid #E6E6E6",
              userSelect: "none",
              textTransform: "uppercase",
              letterSpacing: 1,
              fontSize: 14,
              width: "100%",
            }}
          >
            <Stack style={{ flex: 1, textAlign: "left" }}>ID</Stack>
            <Stack style={{ flex: 2, textAlign: "left" }}>Item SKU</Stack>
            <Stack style={{ flex: 1, textAlign: "left" }}>EAN Code</Stack>
            <Stack style={{ flex: 1.5, textAlign: "left" }}>Description</Stack>
            <Stack style={{ flex: 0.5, textAlign: "left" }}>Quantity</Stack>
          </Stack>

          {/* Data Rows */}
          <Stack style={{ marginTop: "25px", fontSize: 14, width: "100%" }}>
            {selectedPOItems.map((poItem, index) => (
              <Fragment key={`po-item-${index}`}>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Stack style={{ flex: 1, textAlign: "left" }}>
                    {poItem.internalId}
                  </Stack>
                  <Stack style={{ flex: 2, textAlign: "left" }}>
                    {poItem.itemSku}
                  </Stack>
                  <Stack style={{ flex: 1, textAlign: "left" }}>
                    {poItem.eanCode}
                  </Stack>
                  <Stack style={{ flex: 1.5, textAlign: "left" }}>
                    {poItem.description}
                  </Stack>
                  <Stack
                    style={{
                      flex: 0.5,
                      alignItems: "flex-start",
                    }}
                  >
                    <CustomTextField
                      id={`quantity-${index}`}
                      label={`Max: ${poItem.remainingQuantity}`}
                      inputProps={{
                        min: 1,
                        max: poItem.remainingQuantity,
                        step: 1,
                      }}
                      handleChange={handleQuantityChange(
                        poItem.id,
                        poItem.remainingQuantity
                      )}
                      style={{ alignItems: "center" }}
                      textFieldStyle={{ width: "50px" }}
                      onBlur={handleBlur(poItem.id, poItem.remainingQuantity)}
                      value={quantityValues[poItem.id]}
                      type="number"
                    />
                  </Stack>
                </Stack>

                {index + 1 !== selectedPOItems.length && (
                  <Stack
                    style={{
                      borderBottom: "1px solid #E6E6E6",
                      margin: "10px 0",
                      width: "100%",
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Stack>
        </Stack>
        <Stack
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 10,
            marginTop: "15px",
          }}
        >
          <CustomButton
            disabled={!selectedPOItems.length}
            label="Back"
            style={{ width: "100px" }}
            onClick={() => setCurrentModalSection("po_items")}
          />
          <CustomButton
            disabled={!selectedPOItems.length}
            label="Next"
            style={{ width: "100px" }}
            onClick={() => {
              setCurrentModalSection("remaining_fields");
              setSelectPurchaseOrderItemsModalVisible(false);
            }}
          />
        </Stack>
      </Stack>
    );
  };

  const renderSelectPurchaseOrderItemsModal = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(5px)",
          zIndex: 1100,
        }}
      >
        <Box
          ref={selectPOModalRef}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
            width: "80vw",
          }}
        >
          <Container maxWidth="sx">
            <Stack
              elevation={12}
              style={{
                background: "white",
                borderRadius: "25px",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack
                style={{
                  height: "40px",
                  background: "green",
                  borderRadius: "25px 25px 0 0",
                }}
              />
              {currentModalSection === "po_items" &&
                (POItemsLoading ? (
                  <CircularProgress
                    size={35}
                    style={{
                      color: "black",
                      alignSelf: "center",
                    }}
                  />
                ) : (
                  renderChoosePOItemsSection()
                ))}
              {currentModalSection === "quantities" &&
                renderChooseQuantitiesSection()}
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  const renderBackButton = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "20px 15px",
        }}
      >
        <Stack
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            cursor: "pointer",
            padding: 5,
          }}
          sx={{
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => (window.location.href = paths.dashboard.inbound.index)}
        >
          <KeyboardBackspace />
          <CustomTypography
            message="Inbound Requests"
            style={{ fontSize: 12 }}
          />
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      <Seo title="Dashboard: New Inbound Request" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack style={{ width: "100%", height: "100%", overflowY: "auto" }}>
          {renderBackButton()}
          {POVerificationLoading || !POIsValid ? (
            !selectPurchaseOrderModalVisible && (
              <Stack style={{ height: "100%", justifyContent: "center" }}>
                <CircularProgress
                  size={35}
                  style={{
                    color: "black",
                    alignSelf: "center",
                  }}
                />
              </Stack>
            )
          ) : (
            <>
              {currentModalSection === "remaining_fields" &&
                renderNewInboundRequestButtons()}
              {renderNewInboundRequestContent()}
              {selectPurchaseOrderItemsModalVisible &&
                renderSelectPurchaseOrderItemsModal()}
            </>
          )}
        </Stack>
      </Box>
      {selectPurchaseOrderModalVisible && renderSelectPurchaseOrderModal()}
    </>
  );
};

export default withInboundProcessGuard(AddInboundRequestPage);
