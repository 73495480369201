import { useEffect, useState } from "react";
import { useAuth } from "src/hooks/use-auth";
import Error401Page from "src/pages/401";
import Error404Page from "src/pages/404";
import Error500Page from "src/pages/500";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/booking-process";
import { thunks as eTafThunks } from "src/thunks/e-taf";
import { slice } from "src/slices/e-taf";
import { paths } from "src/paths";
import { SplashScreen } from "../components/splash-screen";

// Defined Brand Type Keys
export const brandTypes = {
  agency: "Agency",
  distribution: "Distribution",
  invested: "Invested",
};

// Defined Group Type Keys
export const groupTypes = {
  tomorrowCrew: "tomorrowCrew",
  customer: "customer",
  brand: "brand",
  agency: "agency",
};

// (Linesheets) - Tomorrow Crew - Permissions
export const linesheetsTomorrowCrewPermissions = {
  admin: "admin",
  preSalesCampaignLinesheet: "preSalesCampaignLinesheet",
};

// (Price Management) - Tomorrow Crew - Permissions
export const priceManagementTomorrowCrewPermissions = {
  admin: "admin",
  preSalesCampaignPriceList: "preSalesCampaignPriceList",
};

// (Product Attributes) - Tomorrow Crew - Permissions
export const productAttributesTomorrowCrewPermissions = {
  admin: "admin",
  opsProductAttribute: "opsProductAttribute",
};

// (Purchase Orders) - Tomorrow Crew - Permissions
export const purchaseOrdersTomorrowCrewPermissions = {
  admin: "admin",
  purchaseOrderAdmin: "purchaseOrderAdmin",
};

// (Price Setup) - Tomorrow Crew - Permissions
export const priceSetupTomorrowCrewPermissions = {
  admin: "admin",
  priceManagementAdmin: "priceManagementAdmin",
  priceManagementFinance: "priceManagementFinance",
  priceManagementOperations: "priceManagementOperations",
  priceManagementSales: "priceManagementSales",
};

// (Price Setup) - Tomorrow Crew - Permissions
export const linesheetSettingsTomorrowCrewPermissions = {
  admin: "admin",
  linesheetSettingsTemplate: "linesheetSettingsTemplate",
  linesheetSettingsZedonk: "linesheetSettingsZedonk",
};

// (eTaf) - Tomorrow Crew - Client Management Permission
export const clientManagementTomorrowCrewPermission = {
  admin: "admin",
  clientManagement: "clientManagement",
  clientManagementAreaManager: "clientManagementAreaManager",
};

// (Booking system) - Tomorrow Crew
export const bookingSystemTomorrowCrewPermissions = {
  admin: "admin",
  bookingSystemAdmin: "bookingSystemAdmin",
  bookingSystemUser: "bookingSystemUser",
};

export const inboundProcessPermission = {
  admin: "admin",
  operationsInbound: "operationsInbound",
};

export const assetsPermission = {
  admin: "admin",
  assets: "assets",
};

//
// Routing
//

export const permissionRouting = (permissions, returnTo) => {
  const permissionsKeys = permissions.map((permission) => permission.key);

  window.location.href = returnTo || paths.dashboard.index;

  if (
    permissionsKeys.includes(
      priceSetupTomorrowCrewPermissions.priceManagementSales
    )
  ) {
    window.location.href =
      returnTo || paths.dashboard.priceSetup.priceSetupSalesIndex;
  }

  if (
    permissionsKeys.includes(
      priceSetupTomorrowCrewPermissions.priceManagementOperations
    )
  ) {
    window.location.href =
      returnTo || paths.dashboard.priceSetup.priceSetupOperationsIndex;
  }

  if (
    permissionsKeys.includes(
      priceSetupTomorrowCrewPermissions.priceManagementFinance
    )
  ) {
    window.location.href =
      returnTo || paths.dashboard.priceSetup.priceSetupFinanceIndex;
  }

  if (
    permissionsKeys.includes(
      priceSetupTomorrowCrewPermissions.priceManagementAdmin
    )
  ) {
    window.location.href =
      returnTo || paths.dashboard.priceSetup.priceSetupFinanceIndex;
  }

  if (
    permissionsKeys.includes(
      priceManagementTomorrowCrewPermissions.preSalesCampaignPriceList
    )
  ) {
    window.location.href = returnTo || paths.dashboard.priceManagement.index;
  }

  if (
    permissionsKeys.includes(
      linesheetsTomorrowCrewPermissions.preSalesCampaignLinesheet
    )
  ) {
    window.location.href = returnTo || paths.dashboard.linesheets.index;
  }

  if (permissionsKeys.includes(priceSetupTomorrowCrewPermissions.admin)) {
    window.location.href = returnTo || paths.dashboard.linesheets.index;
  }

  if (
    permissionsKeys.includes(
      purchaseOrdersTomorrowCrewPermissions.purchaseOrderAdmin
    )
  ) {
    window.location.href = returnTo || paths.dashboard.purchaseOrders.index;
  }
};

//
// Linesheets
//

// User role Brand Guard or Tomorrow Crew + Permissions
export const LinesheetsTomorrowCrewOrBrandGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // User Group: Tomorrow Crew
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(linesheetsTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  // User Group: Brand
  if (userGroup === groupTypes.brand) {
    userAccess = true;
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

//
// Price Management
//

// User role Tomorrow Crew + Permissions
export const PriceManagementTomorrowCrewGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // If user group is Tomorrow Crew access is granted
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(priceManagementTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

//
// Product Attributes

export const ProductAttributesTomorrowCrewOrBrandGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // If user group is Tomorrow Crew and has Product Attribute Permissions
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(productAttributesTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  // User Group: Brand
  if (userGroup === groupTypes.brand) {
    userAccess = true;
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

//
// Purchase Orders
//

export const PurchaseOrdersTomorrowCrewOrBrandGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // If user group is Tomorrow Crew and has Purchase Orders Permissions
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(purchaseOrdersTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  // User Group: Brand
  if (userGroup === groupTypes.brand) {
    userAccess = true;
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

//
// Price Setup
//

// User role Tomorrow Crew Guard + Price Setup Permissions
export const PriceSetupTomorrowCrewGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // If user group is Tomorrow Crew and has Price Setup Permissions
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(priceSetupTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

// User role Tomorrow Crew Guard + Price Setup Permissions
export const LinesheetSettingsTomorrowCrewGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // If user group is Tomorrow Crew and has Linesheet Settings Permissions
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(linesheetSettingsTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

export const ClientManagementTomorrowCrewGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // If user group is Tomorrow Crew and has Client Management Permissions
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(clientManagementTomorrowCrewPermission).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

export const BookingSystemTomorrowCrewGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // If user group is Tomorrow Crew and has Booking system Permissions
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(bookingSystemTomorrowCrewPermissions).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

export const UserIsInDBGuard = (props) => {
  const dispatch = useDispatch();
  const {
    storeName,
    storeNameLoading,
    salesCampaignFlag,
    salesCampaignFlagLoading,
  } = useSelector((state) => state.bookingProcess);

  const { children } = props;
  const params = useParams();
  const salesCampaignId = params?.sc_id;
  const userId = params?.id;

  useEffect(() => {
    dispatch(thunks.getStoreName(userId));
    dispatch(thunks.verifySalesCampaign(userId, salesCampaignId));
  }, []);

  if (!salesCampaignFlag && salesCampaignFlagLoading) {
    return <Error401Page />;
  }

  return <>{children}</>;
};

export const CustomerIsInDBGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  const userGroup = user?.group?.key;

  if (!user || userGroup !== groupTypes.customer) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

export const CompanyHashIsValid = (props) => {
  const { children } = props;
  const { user } = useAuth();
  const params = useParams();
  const dispatch = useDispatch();
  const { companyData, companyDataLoading } = useSelector(
    (state) => state.eTaf
  );

  const companyHash = localStorage.getItem("selectedCompanyHash");

  const userGroup = user?.group?.key;

  useEffect(() => {
    dispatch(eTafThunks.verifyCompanyHash(user.hashedId, companyHash));
  }, []);

  if (!user || (!companyData && !companyDataLoading)) {
    // If the user is not customer return a 404 page
    if (userGroup === groupTypes.customer) {
      localStorage.setItem("selectedCompanyHash", companyHash);
      dispatch(slice.actions.setCompanyHash(companyHash));

      window.location.href = paths.customers.companyForm.index;
    } else {
      return <Error404Page />;
    }
  }

  if (companyDataLoading || !companyData) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export const UserIsBrandOrTomorrowCrew = (props) => {
  const { children } = props;
  const { user } = useAuth();

  const userGroup = user?.group?.key;

  if (
    !user ||
    (userGroup !== groupTypes.brand && userGroup !== groupTypes.tomorrowCrew)
  ) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

export const InboundProcessGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // If user group is Tomorrow Crew or Brand and has Assets Permissions
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(inboundProcessPermission).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  if (userGroup === groupTypes.brand) {
    userAccess = true;
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};

export const AssetsGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  let userAccess = false;
  const userGroup = user?.group?.key;
  const userPermissions = user?.permissions;

  // If user group is Tomorrow Crew and has Assets Permissions
  if (userGroup === groupTypes.tomorrowCrew) {
    const hasPermission = userPermissions.some((obj) =>
      Object.entries(assetsPermission).some(
        ([key, value]) => obj["key"] === value
      )
    );

    // Access is granted
    if (hasPermission) {
      userAccess = true;
    }
  }

  if (!userAccess) {
    return <Error404Page />;
  }

  return <>{children}</>;
};
