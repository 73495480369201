import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useAuth } from "src/hooks/use-auth";
import { withBookingSystemGuard } from "src/hocs/with-role-based-guard";
import Error404Page from "src/pages/404";
import { bookingSystemTomorrowCrewPermissions } from "src/guards/role-based-guard";
// Redux Toolkit
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/booking-system";
import moment from "moment";
import ListViewPage from "./list-view";
import CalendarViewPage from "./calendar-view";

const AllBookingsPage = () => {
  // Redundant permission check
  const { user } = useAuth();
  const userPermissions = user?.permissions;

  const hasAccess = userPermissions?.find(
    (object) =>
      object.key === bookingSystemTomorrowCrewPermissions.admin ||
      object.key === bookingSystemTomorrowCrewPermissions.bookingSystemAdmin ||
      object.key === bookingSystemTomorrowCrewPermissions.bookingSystemUser
  );

  if (!hasAccess) {
    return <Error404Page />;
  }

  // User Group
  const userGroup = user.group?.key;
  const userId = user.hashedId;
  const [pageView, setPageView] = useState("List");
  const {
    allBookings,
    allBookingsLoading,
    newClientDropdownValues,
    newClientDropdownValuesLoading,
    salesCampaignDropdownValues,
    allLocations,
    exportLoading,
  } = useSelector((state) => state.bookingSystem);
  const dispatch = useDispatch();

  // Filtering
  const [filters, setFilters] = useState({
    selectedBrand: null,
    selectedCategory: null,
    selectedSeason: null,
    selectedLocation: null,
    fromDate: null,
    toDate: null,
    selectedMeetingType: null,
    selectedSyncedWithHubspot: null,
    allBookingsRows: allBookings,
  });

  const applyFilters = (filters) => {
    const allBookingsRows = allBookings.filter((booking) => {
      return (
        (!filters.selectedBrand ||
          booking.brands?.includes(filters.selectedBrand)) &&
        (!filters.selectedCategory ||
          booking.categories?.includes(filters.selectedCategory)) &&
        (!filters.selectedSeason ||
          booking.season === filters.selectedSeason) &&
        (!filters.selectedLocation ||
          booking.location?.includes(filters.selectedLocation)) &&
        (!filters.selectedMeetingType ||
          booking.meetingType === filters.selectedMeetingType) &&
        (!filters.selectedSyncedWithHubspot ||
          booking.syncedWithHubspot ===
            (filters.selectedSyncedWithHubspot === "Yes")) &&
        (!filters.fromDate ||
          moment(filters.fromDate).format("YYYY/MM/DD") <=
            moment(booking.date).format("YYYY/MM/DD")) &&
        (!filters.toDate ||
          moment(filters.toDate).format("YYYY/MM/DD") >=
            moment(booking.date).format("YYYY/MM/DD"))
      );
    });

    setFilters((prevState) => ({
      ...prevState,
      ...filters,
      allBookingsRows,
    }));
  };

  const handleFilterChange = (filterName, value) => {
    applyFilters({
      ...filters,
      [filterName]: value,
    });
  };

  // This function is used for the localStorage values retrieval
  const handleMultipleFiltersChange = (currentFilters) => {
    const filterObject = {
      ...filters,
    };

    for (let [key, value] of Object.entries(currentFilters)) {
      filterObject[key] = value;
    }

    applyFilters(filterObject);
  };

  const renderResetFiltersButton = () => {
    const [hoveredButton, setHoveredButton] = useState(false);

    const disableButton =
      !filters.selectedBrand &&
      !filters.selectedCategory &&
      !filters.selectedSeason &&
      !filters.selectedLocation &&
      !filters.fromDate &&
      !filters.toDate &&
      !filters.selectedMeetingType &&
      !filters.selectedSyncedWithHubspot;

    const handleResetFilters = () => {
      handleMultipleFiltersChange({
        selectedBrand: null,
        selectedCategory: null,
        selectedSeason: null,
        selectedLocation: null,
        fromDate: null,
        toDate: null,
        selectedMeetingType: null,
        selectedSyncedWithHubspot: null,
      });
    };

    return (
      <Button
        onMouseEnter={() => setHoveredButton(true)}
        onMouseLeave={() => setHoveredButton(false)}
        style={{
          fontFamily: "tmwCustomFont",
          textTransform: "uppercase",
          borderRadius: "25px",
          border: disableButton ? "1px solid #E6E6E6" : "1px solid red",
          transition: "ease-out 0.15s",
          letterSpacing: 2,
          fontSize: 11,
          userSelect: "none",
          fontWeight: 400,
          color: disableButton ? "#BEBEBE" : hoveredButton ? "white" : "red",
          background: disableButton ? "white" : hoveredButton ? "red" : "white",
        }}
        onClick={() => handleResetFilters()}
        disabled={disableButton}
        disableTouchRipple
      >
        Reset filters
      </Button>
    );
  };

  useEffect(() => {
    dispatch(thunks.getAllBookings(userId));
    dispatch(thunks.getAllLocations(userId));
  }, []);

  useEffect(() => {
    //
    // Filters
    //

    // Brand
    const savedBrandFilters = JSON.parse(
      localStorage.getItem("bookingBrandFilters")
    );

    const savedCategoryFilters = JSON.parse(
      localStorage.getItem("bookingCategoryFilters")
    );

    const savedSeasonFilters = JSON.parse(
      localStorage.getItem("bookingSeasonFilters")
    );

    const savedLocationFilters = JSON.parse(
      localStorage.getItem("bookingLocationFilters")
    );

    const savedFromDateFilters = JSON.parse(
      localStorage.getItem("bookingFromDateFilters")
    );

    const savedToDateFilters = JSON.parse(
      localStorage.getItem("bookingToDateFilters")
    );

    const savedMeetingTypeFilters = JSON.parse(
      localStorage.getItem("bookingMeetingTypeFilters")
    );

    const savedSyncedWithHubspotFilters = JSON.parse(
      localStorage.getItem("bookingSyncedWithHubspotFilters")
    );

    handleMultipleFiltersChange({
      selectedBrand: savedBrandFilters,
      selectedCategory: savedCategoryFilters,
      selectedSeason: savedSeasonFilters,
      selectedLocation: savedLocationFilters,
      fromDate: savedFromDateFilters,
      toDate: savedToDateFilters,
      selectedMeetingType: savedMeetingTypeFilters,
      selectedSyncedWithHubspot: savedSyncedWithHubspotFilters,
    });
  }, [allBookings]);

  useEffect(() => {
    // Brand
    localStorage.setItem(
      "bookingBrandFilters",
      JSON.stringify(filters.selectedBrand)
    );

    // Category
    localStorage.setItem(
      "bookingCategoryFilters",
      JSON.stringify(filters.selectedCategory)
    );

    // Season
    localStorage.setItem(
      "bookingSeasonFilters",
      JSON.stringify(filters.selectedSeason)
    );

    // Location
    localStorage.setItem(
      "bookingLocationFilters",
      JSON.stringify(filters.selectedLocation)
    );

    // From Date
    localStorage.setItem(
      "bookingFromDateFilters",
      JSON.stringify(filters.fromDate)
    );

    // To Date
    localStorage.setItem(
      "bookingToDateFilters",
      JSON.stringify(filters.toDate)
    );

    // Meeting Type
    localStorage.setItem(
      "bookingMeetingTypeFilters",
      JSON.stringify(filters.selectedMeetingType)
    );

    // Synced with Hubspot
    localStorage.setItem(
      "bookingSyncedWithHubspotFilters",
      JSON.stringify(filters.selectedSyncedWithHubspot)
    );
  }, [filters]);

  return pageView === "List" ? (
    <ListViewPage
      filters={filters}
      setFilters={setFilters}
      handleFilterChange={handleFilterChange}
      renderResetFiltersButton={renderResetFiltersButton}
      pageView={pageView}
      setPageView={setPageView}
      allBookings={allBookings}
      allBookingsLoading={allBookingsLoading}
      newClientDropdownValues={newClientDropdownValues}
      newClientDropdownValuesLoading={newClientDropdownValuesLoading}
      salesCampaignDropdownValues={salesCampaignDropdownValues}
      allLocations={allLocations}
      exportLoading={exportLoading}
    />
  ) : pageView === "Calendar" ? (
    <CalendarViewPage
      filters={filters}
      setFilters={setFilters}
      handleFilterChange={handleFilterChange}
      renderResetFiltersButton={renderResetFiltersButton}
      pageView={pageView}
      setPageView={setPageView}
      allBookings={allBookings}
      allBookingsLoading={allBookingsLoading}
      newClientDropdownValues={newClientDropdownValues}
      newClientDropdownValuesLoading={newClientDropdownValuesLoading}
      salesCampaignDropdownValues={salesCampaignDropdownValues}
      allLocations={allLocations}
      exportLoading={exportLoading}
    />
  ) : null;
};

export default withBookingSystemGuard(AllBookingsPage);
