import { useEffect, useRef, useState, Fragment } from "react";
import { withInboundProcessGuard } from "src/hocs/with-role-based-guard";
import { paths } from "src/paths";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Container,
  Input,
  MenuItem,
  Stack,
  SvgIcon,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Seo } from "src/components/seo";
import {
  CustomTextField,
  CustomTypography,
  CustomButton,
} from "src/components/inputs";
// Redux Toolkit
import { thunks } from "src/thunks/inbound-process";
import { slice } from "src/slices/inbound-process";
import { useDispatch, useSelector } from "src/store";
import { useAuth } from "src/hooks/use-auth";
import { CloseOutlined, KeyboardBackspace } from "@mui/icons-material";
import { getInboundStatusType } from "./inbound-table-settings";
import { groupTypes } from "src/guards/role-based-guard";
import moment from "moment";
// Icons
import redTmwWarningIcon from "src/icons/tmw-ui/tmw-warning-icon.png";
import UndoIcon from "@mui/icons-material/Undo";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

const AddInboundRequestPage = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const params = useParams();
  const selectPOModalRef = useRef(null);

  // Params
  const inboundRequestId = params.inbound_request_id;

  const {
    inboundRequest,
    inboundRequestLoading,
    POItems,
    POItemsLoading,
    submitInboundRequestLoading,
    submitInboundRequestError,
    inboundRequestStatusChangeSubmitLoading,
    inboundRequestStatusChangeDraftLoading,
    inboundRequestStatusChangeApproveLoading,
  } = useSelector((state) => state.inboundProcess);

  const [selectPurchaseOrderModalVisible, setSelectPurchaseOrderModalVisible] =
    useState(false);
  const [selectedPOItems, setSelectedPOItems] = useState([]);
  const [
    selectPurchaseOrderItemsModalVisible,
    setSelectPurchaseOrderItemsModalVisible,
  ] = useState(false);
  const [currentModalSection, setCurrentModalSection] =
    useState("remaining_fields");
  const [quantityValues, setQuantityValues] = useState({});
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [notesModalVisible, setNotesModalVisible] = useState(false);
  const [draftNotes, setDraftNotes] = useState("");
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [invoiceNetTotal, setInvoiceNetTotal] = useState("");

  //
  // FIELDS
  //
  // const [inboundRequestInformation, setInboundRequestInformation] =
  //   useState("");
  // const [inboundShippingNumber, setInboundShippingNumber] = useState("");
  // const [trackingNumber, setTrackingNumber] = useState("");
  // const [courierInformation, setCourierInformation] = useState("");
  //
  // FILE SELECTS
  //
  // Packing List
  const [packingListFileName, setPackingListFileName] = useState("");
  const [packingListFileData, setPackingListFileData] = useState(null);
  // Invoice
  const [invoiceFileName, setInvoiceFileName] = useState("");
  const [invoiceFileData, setInvoiceFileData] = useState(null);
  // // Photos
  // const [photosFileName, setPhotosFileName] = useState("");
  // const [photosFileData, setPhotosFileData] = useState(null);
  // // Credit Note Provided By 3PL
  // const [creditNoteProvidedBy3PLFileName, setCreditNoteProvidedBy3PLFileName] =
  //   useState("");
  // const [creditNoteProvidedBy3PLFileData, setCreditNoteProvidedBy3PLFileData] =
  //   useState(null);
  // // Credit Note Provided By Brand
  // const [
  //   creditNoteProvidedByBrandFileName,
  //   setCreditNoteProvidedByBrandFileName,
  // ] = useState("");
  // const [
  //   creditNoteProvidedByBrandFileData,
  //   setCreditNoteProvidedByBrandFileData,
  // ] = useState(null);

  useEffect(() => {
    if (inboundRequestId) {
      dispatch(thunks.getInboundRequest(inboundRequestId));
    }
  }, [inboundRequestId]);

  useEffect(() => {
    if (
      inboundRequest?.purchase_order &&
      inboundRequest?.purchase_order_items
    ) {
      setSelectedPOItems(inboundRequest.purchase_order_items);

      setPackingListFileName(inboundRequest.packingListDocument);
      setPackingListFileData(null);
      setInvoiceFileName(inboundRequest.invoice);
      setInvoiceFileData(null);
      setInvoiceNetTotal(inboundRequest.invoiceNetTotal);

      dispatch(thunks.getPOItems(inboundRequest.purchase_order.internalId));
    }
  }, [inboundRequest]);

  useEffect(() => {
    const initialValues = {};

    selectedPOItems.forEach((poItem) => {
      const foundIRPOItem = inboundRequest.purchase_order_items.find(
        (poItem2) => poItem2.id === poItem.id
      );
      const foundPOItem = inboundRequest.poItemsQuantities.find(
        (poItem2) => poItem2.po_item_id === foundIRPOItem?.internalId
      );

      initialValues[poItem.id] = foundPOItem?.qty || 0;
    });

    setQuantityValues(initialValues);
  }, [selectedPOItems]);

  const handleEditInboundRequest = () => {
    const formattedSelectedPOItems = selectedPOItems.map((poItem) => ({
      ...poItem,
      selectedQty: quantityValues[poItem.id],
    }));

    const formData = new FormData();

    // Append fields
    formData.append("author", user.email);
    formData.append("purchaseOrderId", inboundRequest.purchase_order.id);
    formData.append("inboundRequestId", inboundRequest.id);
    formData.append(
      "documentNumber",
      inboundRequest.purchase_order.documentNumber
    );
    // formData.append("inboundRequestInformation", inboundRequestInformation);
    // formData.append("inboundShippingNumber", inboundShippingNumber);
    // formData.append("trackingNumber", trackingNumber);
    // formData.append("courierInformation", courierInformation);

    // Append PO Items
    formData.append("poItems", JSON.stringify(formattedSelectedPOItems));

    // Append files
    if (packingListFileData instanceof File) {
      formData.append("packingList", packingListFileData);
    }

    if (invoiceFileData instanceof File) {
      formData.append("invoice", invoiceFileData);
    }

    formData.append("invoiceNetTotal", invoiceNetTotal);

    dispatch(thunks.editInboundRequest(formData));
  };

  const handleSubmitInboundRequest = () => {
    dispatch(thunks.submitInboundRequest(inboundRequest.id, user.id));
  };

  const handleDraftInboundRequest = () => {
    setNotesModalVisible(true);
  };

  const handleApproveInboundRequest = () => {
    dispatch(thunks.approveInboundRequest(inboundRequest.id, user.id));
  };

  const renderBackButton = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "20px 25px",
        }}
      >
        <Stack
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            cursor: "pointer",
            padding: 5,
          }}
          sx={{
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => (window.location.href = paths.dashboard.inbound.index)}
        >
          <KeyboardBackspace />
          <CustomTypography
            message="Inbound Requests"
            style={{ fontSize: 12 }}
          />
        </Stack>
      </Stack>
    );
  };

  const renderPOItems = () => {
    return (
      <Stack
        style={{
          width: "100%",
          alignItems: "center",
        }}
      >
        <Stack
          style={{
            position: "relative",
            width: "95%",
            padding: "25px",
            border:
              inboundRequest.status === getInboundStatusType.approved
                ? "1px solid green"
                : inboundRequest.status === getInboundStatusType.draft
                ? "1px solid orange"
                : inboundRequest.status === getInboundStatusType.pending
                ? "1px solid red"
                : "none",
          }}
        >
          {inboundRequest.status !== getInboundStatusType.approved &&
            inboundRequest.status !== getInboundStatusType.closed &&
            !(
              inboundRequest.status === getInboundStatusType.pending &&
              user.group.key === groupTypes.brand
            ) && (
              <Stack style={{ position: "absolute", right: 25 }}>
                <CustomButton
                  label="Edit Items"
                  style={{ background: "white" }}
                  onClick={() => {
                    setSelectPurchaseOrderItemsModalVisible(true);
                    setCurrentModalSection("po_items");
                  }}
                />
              </Stack>
            )}
          <CustomTypography
            message="Purchase Order Items:"
            title
            style={{ marginBottom: "35px" }}
          />
          <Stack style={{ width: "100%", alignItems: "center" }}>
            <Stack
              style={{
                display: "flex",
                width: "90%",
                gap: 10,
              }}
            >
              {/* Header */}
              <Stack
                style={{
                  flexDirection: "row",
                  fontWeight: "bold",
                  borderBottom: "1px solid #E6E6E6",
                  paddingBottom: 5,
                }}
              >
                <Stack style={{ flex: 1 }}>Internal ID</Stack>
                <Stack style={{ flex: 2 }}>SKU</Stack>
                <Stack style={{ flex: 1 }}>EAN Code</Stack>
                <Stack style={{ flex: 2 }}>Description</Stack>
                <Stack style={{ flex: 1 }}>Quantity</Stack>
              </Stack>

              {/* POItems */}
              {selectedPOItems.map((POItem, index) => {
                const foundItemsQuantity = quantityValues[POItem?.id];

                return (
                  <Stack
                    key={`po-item-${index}`}
                    style={{ flexDirection: "row" }}
                  >
                    <Stack style={{ flex: 1 }}>{POItem.internalId}</Stack>
                    <Stack style={{ flex: 2 }}>{POItem.itemSku}</Stack>
                    <Stack style={{ flex: 1 }}>{POItem.eanCode}</Stack>
                    <Stack style={{ flex: 2 }}>{POItem.description}</Stack>
                    <Stack style={{ flex: 1 }}>{foundItemsQuantity}</Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const renderInputSelect = (
    inputName,
    fileName,
    setFileName,
    fileData,
    setFileData,
    allowedFiles
  ) => {
    const handleFileChange = (event) => {
      const file = event.target.files[0];

      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (allowedFiles.includes(fileExtension)) {
          setFileName(file.name);
          setFileData(file);
          setFieldsChanged(true);
        } else {
          alert("Invalid file type! Please upload a PDF file.");

          event.target.value = "";
          setFileName("");
          setFileData(null);
        }
      }
    };

    const deleteFileEnabled =
      inboundRequest.status !== getInboundStatusType.approved &&
      inboundRequest.status !== getInboundStatusType.closed &&
      !(
        inboundRequest.status === getInboundStatusType.pending &&
        user.group.key === groupTypes.brand
      );

    return (
      <Stack style={{ height: "50px", alignItems: "flex-start" }}>
        <CustomButton
          component="label"
          disabled={!!(fileName || fileData)}
          label={
            <>
              {inputName}
              <Input
                type="file"
                id={inputName}
                accept={allowedFiles.map((ext) => `.${ext}`).join(", ")}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </>
          }
          style={{ width: "fit-content" }}
        />
        {fileName && (
          <Stack
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
              margin: "10px",
              fontFamily: "tmwCustomFont",
              fontSize: 12,
              color: deleteFileEnabled ? "black" : "gray",
            }}
          >
            {fileName}
            {deleteFileEnabled && (
              <ButtonBase
                onClick={() => {
                  setFileName("");
                  setFileData(null);

                  // Reset the file input on close
                  const fileInput = document.getElementById(inputName);
                  if (fileInput) {
                    fileInput.value = "";
                  }
                }}
              >
                <CloseOutlined style={{ width: "18px" }} />
              </ButtonBase>
            )}
          </Stack>
        )}
      </Stack>
    );
  };

  const renderNewInboundRequestButtons = () => {
    const filesPresent =
      (packingListFileData instanceof File || packingListFileName) &&
      (invoiceFileData instanceof File || invoiceFileName);
    const invoiceNetTotalNotChanged =
      Number(invoiceNetTotal) === inboundRequest.invoiceNetTotal;

    return (
      <Stack
        style={{
          position: "fixed",
          background: "white",
          right: 0,
          bottom: 0,
          flexDirection: "row",
          gap: 10,
          justifyContent: "flex-end",
          zIndex: 500,
          padding: "25px",
        }}
      >
        <CustomButton
          style={{
            background: "white",
          }}
          redButton={submitInboundRequestError}
          label="Save Changes"
          disabled={
            !filesPresent || (!fieldsChanged && invoiceNetTotalNotChanged)
          }
          onClick={handleEditInboundRequest}
          loader={
            submitInboundRequestLoading && (
              <CircularProgress
                size={20}
                color="inherit"
                style={{ marginLeft: "10px" }}
              />
            )
          }
        />
      </Stack>
    );
  };

  const renderChooseQuantitiesSection = () => {
    const handleQuantityChange = (name, maxValue) => (event) => {
      let value = event.target.value;

      // Allow empty input (so users can delete & retype)
      if (value === "") {
        setQuantityValues((prev) => ({ ...prev, [name]: "" }));
        setFieldsChanged(true);
        return;
      }

      let num = Number(value);

      // Allow only numbers within range while typing
      if (!isNaN(num) && num >= 0 && num <= maxValue) {
        setQuantityValues((prev) => ({ ...prev, [name]: num }));
        setFieldsChanged(true);
      }
    };

    const handleBlur = (name, maxValue) => () => {
      let num = quantityValues[name];

      // Auto-correct empty values or out-of-range inputs
      if (num === "" || num < 1) num = 1;
      if (num > maxValue) num = maxValue;

      setQuantityValues((prev) => ({ ...prev, [name]: num }));
      setFieldsChanged(true);
    };

    return (
      <Stack
        style={{
          gap: 25,
          padding: "25px",
        }}
      >
        <CustomTypography
          message="Select The Purchase Order Items Quantities:"
          title
        />
        <Stack
          style={{
            maxHeight: "50vh",
            overflow: "auto",
            fontFamily: "tmwCustomFont",
          }}
        >
          {/* Header Row */}
          <Stack
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontWeight: "bold",
              padding: "5px 0",
              borderBottom: "1px solid #E6E6E6",
              userSelect: "none",
              textTransform: "uppercase",
              letterSpacing: 1,
              fontSize: 14,
              width: "100%",
            }}
          >
            <Stack style={{ flex: 1, textAlign: "left" }}>ID</Stack>
            <Stack style={{ flex: 2, textAlign: "left" }}>Item SKU</Stack>
            <Stack style={{ flex: 1, textAlign: "left" }}>EAN Code</Stack>
            <Stack style={{ flex: 1.5, textAlign: "left" }}>Description</Stack>
            <Stack style={{ flex: 0.5, textAlign: "left" }}>Quantity</Stack>
          </Stack>

          {/* Data Rows */}
          <Stack style={{ marginTop: "25px", fontSize: 14, width: "100%" }}>
            {[...selectedPOItems]
              ?.sort((a, b) => a.id - b.id)
              ?.map((poItem, index) => (
                <Fragment key={`po-item-${index}`}>
                  <Stack
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Stack style={{ flex: 1, textAlign: "left" }}>
                      {poItem.internalId}
                    </Stack>
                    <Stack style={{ flex: 2, textAlign: "left" }}>
                      {poItem.itemSku}
                    </Stack>
                    <Stack style={{ flex: 1, textAlign: "left" }}>
                      {poItem.eanCode}
                    </Stack>
                    <Stack style={{ flex: 1.5, textAlign: "left" }}>
                      {poItem.description}
                    </Stack>
                    <Stack
                      style={{
                        flex: 0.5,
                        alignItems: "flex-start",
                      }}
                    >
                      <CustomTextField
                        id={`quantity-${index}`}
                        label={`Max: ${poItem.remainingQuantity}`}
                        inputProps={{
                          min: 0,
                          max: poItem.remainingQuantity,
                          step: 1,
                        }}
                        handleChange={handleQuantityChange(
                          poItem.id,
                          poItem.remainingQuantity
                        )}
                        style={{ alignItems: "center" }}
                        textFieldStyle={{ width: "50px" }}
                        onBlur={handleBlur(poItem.id, poItem.remainingQuantity)}
                        value={quantityValues[poItem.id]}
                        type="number"
                      />
                    </Stack>
                  </Stack>

                  {index + 1 !== selectedPOItems.length && (
                    <Stack
                      style={{
                        borderBottom: "1px solid #E6E6E6",
                        margin: "10px 0",
                        width: "100%",
                      }}
                    />
                  )}
                </Fragment>
              ))}
          </Stack>
        </Stack>
        <Stack
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 10,
            marginTop: "15px",
          }}
        >
          <CustomButton
            disabled={!selectedPOItems.length}
            label="Back"
            style={{ width: "100px" }}
            onClick={() => setCurrentModalSection("po_items")}
          />
          <CustomButton
            disabled={
              !selectedPOItems.length ||
              Object.values(quantityValues).indexOf(0) !== -1
            }
            label="Next"
            style={{ width: "100px" }}
            onClick={() => {
              setCurrentModalSection("remaining_fields");
              setSelectPurchaseOrderItemsModalVisible(false);
            }}
          />
        </Stack>
      </Stack>
    );
  };

  const renderChoosePOItemsSection = () => {
    return (
      <Stack style={{ gap: 50, padding: "25px" }}>
        <CustomTypography message="Select The Purchase Order Items:" title />
        <Stack style={{ gap: 5, fontFamily: "tmwCustomFont" }}>
          {/* Column Labels */}
          <Stack
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
              fontWeight: "bold",
              padding: "5px 0",
              borderBottom: "1px solid #E6E6E6",
              userSelect: "none",
              paddingLeft: "55px",
              textTransform: "uppercase",
              letterSpacing: 1,
              fontSize: 14,
            }}
          >
            <Stack style={{ flex: 1, textAlign: "left" }}>ID</Stack>
            <Stack style={{ flex: 1, textAlign: "left" }}>Item SKU</Stack>
            <Stack style={{ flex: 1, textAlign: "left" }}>EAN Code</Stack>
            <Stack
              style={{
                flex: 2,
                textAlign: "left",
              }}
            >
              Description
            </Stack>
            <Stack style={{ flex: 1, textAlign: "left" }}>Quantity Left</Stack>
          </Stack>

          {/* Selectable Items */}
          <Stack style={{ maxHeight: "50vh", overflow: "auto" }}>
            {[...POItems]
              ?.sort((a, b) => a.id - b.id)
              ?.map((POItem, index) => {
                const isChecked = selectedPOItems.some(
                  (item) => item.id === POItem.id
                );

                return (
                  <Stack
                    key={`POItem-${index}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 10,
                      margin: "2px 0",
                      cursor: "pointer",
                      padding: "5px",
                      transition: "background-color 0.2s ease-in-out",
                      userSelect: "none",
                      fontSize: 14,
                      height: "50px",
                    }}
                    sx={{
                      color: "black",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={() => {
                      const currentPOItem =
                        inboundRequest.purchase_order_items.find(
                          (item) => item?.id === POItem.id
                        );

                      setSelectedPOItems(
                        (prev) =>
                          isChecked
                            ? prev.filter((item) => item.id !== POItem.id) // Remove object by matching id
                            : [...prev, currentPOItem || POItem] // Add the whole POItem object
                      );
                    }}
                  >
                    <Checkbox
                      className="hover-checkbox"
                      checked={isChecked}
                      onChange={(e) => e.stopPropagation()}
                      style={{
                        color: "black",
                        borderRadius: 0,
                        padding: 0,
                        marginTop: -5,
                      }}
                    />
                    <Stack style={{ flex: 1, textAlign: "left" }}>
                      {POItem.internalId}
                    </Stack>
                    <Stack
                      style={{
                        flex: 1,
                        textAlign: "left",
                      }}
                    >
                      {POItem.itemSku}
                    </Stack>
                    <Stack style={{ flex: 1, textAlign: "left" }}>
                      {POItem.eanCode}
                    </Stack>
                    <Stack
                      style={{
                        flex: 2,
                        textAlign: "left",
                      }}
                    >
                      {POItem.description}
                    </Stack>
                    <Stack
                      style={{
                        flex: 1,
                        textAlign: "left",
                        margin: "0 -50px 0 50px",
                      }}
                    >
                      {POItem.remainingQuantity} / {POItem.qty}
                    </Stack>
                  </Stack>
                );
              })}
          </Stack>
        </Stack>
        <Stack
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 10,
            marginTop: "15px",
          }}
        >
          <CustomButton
            label="Cancel"
            style={{ width: "100px" }}
            onClick={() => {
              window.location.reload();
            }}
          />
          <CustomButton
            disabled={!selectedPOItems.length}
            label="Next"
            style={{ width: "100px" }}
            onClick={() => setCurrentModalSection("quantities")}
          />
        </Stack>
      </Stack>
    );
  };

  const renderSelectPurchaseOrderItemsModal = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(5px)",
          zIndex: 1100,
        }}
      >
        <Box
          ref={selectPOModalRef}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
            width: "80vw",
          }}
        >
          <Container maxWidth="sx">
            <Stack
              elevation={12}
              style={{
                background: "white",
                borderRadius: "25px",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack
                style={{
                  height: "40px",
                  background: "green",
                  borderRadius: "25px 25px 0 0",
                }}
              />
              {currentModalSection === "po_items" &&
                (POItemsLoading ? (
                  <CircularProgress
                    size={35}
                    style={{
                      color: "black",
                      alignSelf: "center",
                    }}
                  />
                ) : (
                  renderChoosePOItemsSection()
                ))}
              {currentModalSection === "quantities" &&
                renderChooseQuantitiesSection()}
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  const renderNotesModal = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(5px)",
          zIndex: 1100,
        }}
      >
        <Box
          ref={selectPOModalRef}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
            width: "80vw",
          }}
        >
          <Container maxWidth="sx">
            <Stack
              elevation={12}
              style={{
                background: "white",
                borderRadius: "25px",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack
                style={{
                  height: "40px",
                  background: "orange",
                  borderRadius: "25px 25px 0 0",
                }}
              />
              <Stack
                style={{
                  gap: 35,
                  padding: "25px",
                  alignItems: "center",
                }}
              >
                <Stack style={{ width: "100%" }}>
                  <CustomTypography
                    message="Insert the change requests:"
                    title
                  />
                </Stack>
                <Stack
                  style={{
                    maxHeight: "50vh",
                    width: "50%",
                    overflow: "auto",
                    fontFamily: "tmwCustomFont",
                  }}
                >
                  <CustomTextField
                    label="Notes"
                    value={draftNotes}
                    handleChange={(e) => setDraftNotes(e.target.value)}
                    multiline
                  />
                </Stack>
              </Stack>
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 15,
                  margin: 25,
                }}
              >
                <CustomButton
                  label="Submit"
                  disabled={!draftNotes.trim()}
                  onClick={() =>
                    dispatch(
                      thunks.draftInboundRequest(
                        inboundRequest.id,
                        user.id,
                        draftNotes
                      )
                    )
                  }
                />
                <CustomButton
                  label="Cancel"
                  onClick={() => setNotesModalVisible(false)}
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  const renderIRActionButtons = () => {
    const filesPresent =
      (packingListFileData instanceof File || packingListFileName) &&
      (invoiceFileData instanceof File || invoiceFileName);

    const disabled = !!(filesPresent && fieldsChanged);

    return (
      <Stack style={{ flexDirection: "row", gap: 10 }}>
        {user.group.key === groupTypes.tomorrowCrew &&
          !!inboundRequest.history && (
            <CustomButton
              label="View History"
              onClick={() => setHistoryModalVisible(true)}
              disabled={
                inboundRequestStatusChangeSubmitLoading ||
                inboundRequestStatusChangeDraftLoading ||
                inboundRequestStatusChangeApproveLoading
              }
            />
          )}
        {inboundRequest?.status === getInboundStatusType.draft && (
          <CustomButton
            label="Confirm Inbound Request"
            style={{ background: "white" }}
            onClick={
              handleSubmitInboundRequest ||
              inboundRequestStatusChangeSubmitLoading
            }
            disabled={
              inboundRequestStatusChangeSubmitLoading ||
              inboundRequestStatusChangeDraftLoading ||
              inboundRequestStatusChangeApproveLoading
            }
            loader={
              inboundRequestStatusChangeSubmitLoading && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ marginLeft: "10px" }}
                />
              )
            }
          />
        )}
        {inboundRequest?.status === getInboundStatusType.pending &&
          user.group.key === groupTypes.tomorrowCrew && (
            <>
              <CustomButton
                label="Draft Inbound Request"
                style={{ background: "white" }}
                onClick={handleDraftInboundRequest}
                disabled={
                  disabled ||
                  inboundRequestStatusChangeSubmitLoading ||
                  inboundRequestStatusChangeDraftLoading ||
                  inboundRequestStatusChangeApproveLoading
                }
                loader={
                  inboundRequestStatusChangeDraftLoading && (
                    <CircularProgress
                      size={20}
                      color="inherit"
                      style={{ marginLeft: "10px" }}
                    />
                  )
                }
              />
              <CustomButton
                label="Approve Inbound Request"
                style={{ background: "white" }}
                onClick={handleApproveInboundRequest}
                disabled={
                  disabled ||
                  inboundRequestStatusChangeSubmitLoading ||
                  inboundRequestStatusChangeDraftLoading ||
                  inboundRequestStatusChangeApproveLoading
                }
                loader={
                  inboundRequestStatusChangeApproveLoading && (
                    <CircularProgress
                      size={20}
                      color="inherit"
                      style={{ marginLeft: "10px" }}
                    />
                  )
                }
              />
            </>
          )}
      </Stack>
    );
  };

  const renderNotesSection = () => {
    return (
      <Stack
        style={{
          height: "100%",
          width: "100%",
          padding: 50,
          gap: 15,
        }}
      >
        <Stack
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <img src={redTmwWarningIcon} style={{ width: 18 }} />
          <CustomTypography message="Notes:" />
        </Stack>
        <Stack
          style={{
            border: "1px solid red",
            padding: 25,
            fontFamily: "tmwCustomFont",
            whiteSpace: "pre",
          }}
        >
          {inboundRequest.notes}
        </Stack>
      </Stack>
    );
  };

  const renderInvoiceNetTotalSection = () => {
    const disabledButton =
      inboundRequest.status === getInboundStatusType.approved ||
      inboundRequest.status === getInboundStatusType.closed;

    return (
      <Stack
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 75,
          margin: "100px 0 50px 0",
        }}
      >
        <CustomTextField
          label="Invoice Net Total"
          style={{ width: "300px" }}
          value={invoiceNetTotal}
          handleChange={(e) => setInvoiceNetTotal(e.target.value)}
          disabled={disabledButton}
        />
        <CustomTextField
          label="Calculated Net Total"
          style={{ width: "300px" }}
          value={inboundRequest.calculatedNetTotal}
          disabled
        />
      </Stack>
    );
  };

  const renderHistorySection = () => {
    const renderHistoryItem = (item, index) => {
      return (
        <Stack key={"inbound-request-history-log" + index}>
          <TimelineItem
            sx={{
              "&::before": {
                display: "none",
              },
            }}
          >
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  border: 0,
                  p: 0,
                }}
              >
                <Avatar>
                  <SvgIcon>
                    {item.type === "Draft" && <UndoIcon />}
                    {item.type === "Creation" && <AddIcon />}
                    {item.type === "Edit" && <EditIcon />}
                    {item.type === "Submit" && <HourglassTopIcon />}
                    {item.type === "Approval" && (
                      <CheckCircleIcon style={{ color: "green" }} />
                    )}
                  </SvgIcon>
                </Avatar>
              </TimelineDot>
              {index !== inboundRequest.history.length - 1 && (
                <TimelineConnector
                  sx={{
                    minHeight: 30,
                  }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  height:
                    index !== inboundRequest.history.length - 1 ? "65%" : "85%",
                }}
              >
                <Stack
                  style={{ flex: 2, textAlign: "left", marginLeft: "15px" }}
                >
                  {item.message}
                </Stack>
                <Stack style={{ flex: 1, textAlign: "left" }}>
                  {moment(item.date).format("YYYY-MM-DD HH:mm")}
                </Stack>
                <Stack style={{ flex: 1, textAlign: "left" }}>{item.by}</Stack>
              </Stack>
            </TimelineContent>
          </TimelineItem>
        </Stack>
      );
    };

    return (
      <Stack
        style={{
          height: "100%",
          width: "100%",
          padding: "35px 50px",
          gap: 15,
        }}
      >
        <Stack
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <CustomTypography message="Request History" title />
        </Stack>
        <Stack
          style={{
            fontFamily: "tmwCustomFont",
            maxHeight: "40vh",
            overflow: "auto",
            scrollbarColor: "lightgrey transparent",
          }}
        >
          <Timeline
            sx={{
              m: 0,
              p: 0,
            }}
          >
            {[...(inboundRequest.history || [])]
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .map((history, index) => renderHistoryItem(history, index))}
          </Timeline>
        </Stack>
      </Stack>
    );
  };

  const renderHistoryModal = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(5px)",
          zIndex: 1100,
        }}
      >
        <Box
          ref={selectPOModalRef}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
            width: "80vw",
          }}
        >
          <Container maxWidth="sx">
            <Stack
              elevation={12}
              style={{
                background: "white",
                borderRadius: "25px",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack
                style={{
                  height: "40px",
                  background: "green",
                  borderRadius: "25px 25px 0 0",
                }}
              />
              {renderHistorySection()}
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 15,
                  margin: "-15px 25px 25px 0",
                }}
              >
                <CustomButton
                  label="Close"
                  onClick={() => setHistoryModalVisible(false)}
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  return (
    <>
      <Seo title="Dashboard: New Inbound Request" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!inboundRequestLoading &&
          (inboundRequest ? (
            <Stack
              style={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "10px 0",
                }}
              >
                {renderBackButton()}
                {renderIRActionButtons()}
              </Stack>
              {renderPOItems()}
              <Stack
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: "50px",
                }}
              >
                <Stack
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    gap: 75,
                    margin: "50px 0 25px 0",
                  }}
                >
                  {renderInputSelect(
                    packingListFileData
                      ? "Packing List Document"
                      : "Upload Packing List Document",
                    packingListFileName,
                    setPackingListFileName,
                    packingListFileData,
                    setPackingListFileData,
                    ["pdf"]
                  )}
                  {renderInputSelect(
                    invoiceFileData ? "Invoice" : "Upload Invoice",
                    invoiceFileName,
                    setInvoiceFileName,
                    invoiceFileData,
                    setInvoiceFileData,
                    ["pdf"]
                  )}
                </Stack>
                {user.group.key === groupTypes.tomorrowCrew &&
                  renderInvoiceNetTotalSection()}
                {!!inboundRequest.notes &&
                  inboundRequest.status === getInboundStatusType.draft &&
                  renderNotesSection()}
                {selectPurchaseOrderItemsModalVisible &&
                  inboundRequest.status !== getInboundStatusType.approved &&
                  inboundRequest.status !== getInboundStatusType.closed &&
                  renderSelectPurchaseOrderItemsModal()}
                {notesModalVisible &&
                  inboundRequest.status !== getInboundStatusType.approved &&
                  inboundRequest.status !== getInboundStatusType.closed &&
                  renderNotesModal()}
                {inboundRequest.status !== getInboundStatusType.approved &&
                  inboundRequest.status !== getInboundStatusType.closed &&
                  !(
                    inboundRequest.status === getInboundStatusType.pending &&
                    user.group.key === groupTypes.brand
                  ) &&
                  renderNewInboundRequestButtons()}
                {historyModalVisible &&
                  user.group.key === groupTypes.tomorrowCrew &&
                  renderHistoryModal()}
              </Stack>
            </Stack>
          ) : (
            <Stack
              style={{
                height: "90%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomTypography
                message="This Inbound Request does not exist or has been deleted."
                title
              />
            </Stack>
          ))}
      </Box>
    </>
  );
};

export default withInboundProcessGuard(AddInboundRequestPage);
