import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Container,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Seo } from "src/components/seo";
// Guard
import { withInboundProcessGuard } from "src/hocs/with-role-based-guard";
import {
  columnsDefinition,
  dataGridStyle,
} from "src/sections/dashboard/inbound/inbound-table-settings";
import { useAuth } from "src/hooks/use-auth";
import { CustomButton, CustomTextField } from "src/components/inputs";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { paths } from "src/paths";
// Redux Toolkit
import { thunks } from "src/thunks/inbound-process";
import { useDispatch, useSelector } from "src/store";
import moment from "moment";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { groupTypes } from "src/guards/role-based-guard";

const Inbound = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  // User Group
  const userGroup = user.group?.key;

  const { inboundRequests } = useSelector((state) => state.inboundProcess);

  // Filtering
  const [filters, setFilters] = useState({
    selectedPODocumentNumber: null,
    selectedInboundShippingNumber: null,
    selectedTrackingNumber: null,
    selectedBrand: null,
    selectedSeason: null,
    selectedAuthor: null,
    fromDate: null,
    toDate: null,
    allInboundRequests: inboundRequests,
  });

  const applyFilters = (filters) => {
    const allInboundRequests = inboundRequests.filter((inboundRequest) => {
      return (
        (!filters.selectedPODocumentNumber ||
          inboundRequest.purchase_order.documentNumber?.includes(
            filters.selectedPODocumentNumber
          )) &&
        (!filters.selectedInboundShippingNumber ||
          inboundRequest.inboundShippingNumber?.includes(
            filters.selectedInboundShippingNumber
          )) &&
        (!filters.selectedTrackingNumber ||
          inboundRequest.trackingNumber === filters.selectedTrackingNumber) &&
        (!filters.selectedBrand ||
          inboundRequest.brandName === filters.selectedBrand) &&
        (!filters.selectedSeason ||
          inboundRequest.season === filters.selectedSeason) &&
        (!filters.selectedAuthor ||
          inboundRequest.author?.includes(filters.selectedAuthor)) &&
        (!filters.fromDate ||
          moment(filters.fromDate).format("YYYY/MM/DD") <=
            moment(inboundRequest.createdAt).format("YYYY/MM/DD")) &&
        (!filters.toDate ||
          moment(filters.toDate).format("YYYY/MM/DD") >=
            moment(inboundRequest.createdAt).format("YYYY/MM/DD"))
      );
    });

    setFilters((prevState) => ({
      ...prevState,
      ...filters,
      allInboundRequests,
    }));
  };

  const handleFilterChange = (filterName, value) => {
    applyFilters({
      ...filters,
      [filterName]: value,
    });
  };

  // This function is used for the localStorage values retrieval
  const handleMultipleFiltersChange = (currentFilters) => {
    const filterObject = {
      ...filters,
    };

    for (let [key, value] of Object.entries(currentFilters)) {
      filterObject[key] = value;
    }

    applyFilters(filterObject);
  };

  const renderResetFiltersButton = () => {
    const [hoveredButton, setHoveredButton] = useState(false);

    const disableButton =
      !filters.selectedPODocumentNumber &&
      !filters.selectedInboundShippingNumber &&
      !filters.selectedTrackingNumber &&
      !filters.selectedBrand &&
      !filters.selectedSeason &&
      !filters.selectedAuthor &&
      !filters.fromDate &&
      !filters.toDate;

    const handleResetFilters = () => {
      handleMultipleFiltersChange({
        selectedPODocumentNumber: null,
        selectedInboundShippingNumber: null,
        selectedTrackingNumber: null,
        selectedBrand: null,
        selectedSeason: null,
        selectedAuthor: null,
        fromDate: null,
        toDate: null,
      });
    };

    return (
      <Button
        onMouseEnter={() => setHoveredButton(true)}
        onMouseLeave={() => setHoveredButton(false)}
        style={{
          fontFamily: "tmwCustomFont",
          textTransform: "uppercase",
          borderRadius: "25px",
          border: disableButton ? "1px solid #E6E6E6" : "1px solid red",
          transition: "ease-out 0.15s",
          letterSpacing: 2,
          fontSize: 11,
          userSelect: "none",
          fontWeight: 400,
          color: disableButton ? "#BEBEBE" : hoveredButton ? "white" : "red",
          background: disableButton ? "white" : hoveredButton ? "red" : "white",
        }}
        onClick={() => handleResetFilters()}
        disabled={disableButton}
        disableTouchRipple
      >
        Reset filters
      </Button>
    );
  };

  useEffect(() => {
    if (userGroup === groupTypes.tomorrowCrew) {
      dispatch(thunks.getInboundRequests());
    } else if (userGroup === groupTypes.brand && user.brand?.name) {
      dispatch(thunks.getInboundRequestsOfBrand(user.brand?.name));
    }
  }, []);

  useEffect(() => {
    //
    // Filters
    //

    // Brand
    const savedPODocumentNumberFilters = JSON.parse(
      localStorage.getItem("inboundRequestPODocumentNumberFilters")
    );

    const savedInboundShippingNumberFilters = JSON.parse(
      localStorage.getItem("inboundRequestInboundShippingNumberFilters")
    );

    const savedTrackingNumberFilters = JSON.parse(
      localStorage.getItem("inboundRequestTrackingNumberFilters")
    );

    const savedBrandFilters = JSON.parse(
      localStorage.getItem("inboundRequestBrandFilters")
    );

    const savedSeasonFilters = JSON.parse(
      localStorage.getItem("inboundRequestSeasonFilters")
    );

    const savedAuthorFilters = JSON.parse(
      localStorage.getItem("inboundRequestAuthorFilters")
    );

    const savedFromDateFilters = JSON.parse(
      localStorage.getItem("inboundRequestFromDateFilters")
    );

    const savedToDateFilters = JSON.parse(
      localStorage.getItem("inboundRequestToDateFilters")
    );

    handleMultipleFiltersChange({
      selectedPODocumentNumber: savedPODocumentNumberFilters,
      selectedInboundShippingNumber: savedInboundShippingNumberFilters,
      selectedTrackingNumber: savedTrackingNumberFilters,
      selectedBrand: savedBrandFilters,
      selectedSeason: savedSeasonFilters,
      selectedAuthor: savedAuthorFilters,
      fromDate: savedFromDateFilters,
      toDate: savedToDateFilters,
    });
  }, [inboundRequests]);

  useEffect(() => {
    // PO Document Number
    localStorage.setItem(
      "inboundRequestPODocumentNumberFilters",
      JSON.stringify(filters.selectedPODocumentNumber)
    );

    // Inbound Shipping Number
    localStorage.setItem(
      "inboundRequestInboundShippingNumberFilters",
      JSON.stringify(filters.selectedInboundShippingNumber)
    );

    // Tracking Number
    localStorage.setItem(
      "inboundRequestTrackingNumberFilters",
      JSON.stringify(filters.selectedTrackingNumber)
    );

    // Brand
    localStorage.setItem(
      "inboundRequestBrandFilters",
      JSON.stringify(filters.selectedBrand)
    );

    // Season
    localStorage.setItem(
      "inboundRequestSeasonFilters",
      JSON.stringify(filters.selectedSeason)
    );

    // Author
    localStorage.setItem(
      "inboundRequestAuthorFilters",
      JSON.stringify(filters.selectedAuthor)
    );

    // From Date
    localStorage.setItem(
      "inboundRequestFromDateFilters",
      JSON.stringify(filters.fromDate)
    );

    // To Date
    localStorage.setItem(
      "inboundRequestToDateFilters",
      JSON.stringify(filters.toDate)
    );
  }, [filters]);

  //
  // Filters Components
  //

  const renderPODocumentNumberAutoselect = () => {
    const poDocumentNumberAutocompleteData = [
      ...new Set(
        inboundRequests?.flatMap(
          (inboundRequest) => inboundRequest?.purchase_order?.documentNumber
        )
      ),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          PO Document Number
        </span>
        <Autocomplete
          id="autocomplete-poDocumentNumber"
          name="autocomplete-poDocumentNumber"
          options={poDocumentNumberAutocompleteData}
          value={filters.selectedPODocumentNumber}
          onChange={(e, value) => {
            handleFilterChange("selectedPODocumentNumber", value);
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "225px",
                }}
                id="poDocumentNumber"
                name="poDocumentNumber"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderInboundShippingNumberAutoselect = () => {
    const inboundShippingNumberAutocompleteData = [
      ...new Set(
        inboundRequests?.flatMap(
          (inboundRequest) => inboundRequest?.inboundShippingNumber
        )
      ),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Inbound Shipping Number
        </span>
        <Autocomplete
          id="autocomplete-inboundShippingNumber"
          name="autocomplete-inboundShippingNumber"
          options={inboundShippingNumberAutocompleteData}
          value={filters.selectedInboundShippingNumber}
          onChange={(e, value) =>
            handleFilterChange("selectedInboundShippingNumber", value)
          }
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "250px",
                }}
                id="selectedInboundShippingNumber"
                name="selectedInboundShippingNumber"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderTrackingNumberAutoselect = () => {
    const trackingNumberAutocompleteData = [
      ...new Set(
        inboundRequests?.map((inboundRequest) => inboundRequest?.trackingNumber)
      ),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Tracking Number
        </span>
        <Autocomplete
          id="autocomplete-trackingNumber"
          name="autocomplete-trackingNumber"
          options={trackingNumberAutocompleteData}
          value={filters.selectedTrackingNumber}
          onChange={(e, value) =>
            handleFilterChange("selectedTrackingNumber", value)
          }
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  minWidth: "200px",
                }}
                id="selectedTrackingNumber"
                name="selectedTrackingNumber"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderBrandAutoselect = () => {
    const brandAutocompleteData = [
      ...new Set(
        inboundRequests?.map((inboundRequest) => inboundRequest?.brandName)
      ),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Brand
        </span>
        <Autocomplete
          id="autocomplete-brand"
          name="autocomplete-brand"
          options={brandAutocompleteData}
          value={filters.selectedBrand}
          onChange={(e, value) => handleFilterChange("selectedBrand", value)}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  minWidth: "150px",
                }}
                id="selectedBrand"
                name="selectedBrand"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderSeasonAutoselect = () => {
    const seasonAutocompleteData = [
      ...new Set(
        inboundRequests?.map((inboundRequest) => inboundRequest?.season)
      ),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Season
        </span>
        <Autocomplete
          id="autocomplete-season"
          name="autocomplete-season"
          options={seasonAutocompleteData}
          value={filters.selectedSeason}
          onChange={(e, value) => handleFilterChange("selectedSeason", value)}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  minWidth: "150px",
                }}
                id="selectedSeason"
                name="selectedSeason"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderAuthorAutoselect = () => {
    const authorAutocompleteData = [
      ...new Set(
        inboundRequests?.map((inboundRequest) => inboundRequest?.author)
      ),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Author
        </span>
        <Autocomplete
          id="autocomplete-author"
          name="autocomplete-author"
          options={authorAutocompleteData}
          value={filters.selectedAuthor}
          onChange={(e, value) => handleFilterChange("selectedAuthor", value)}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                    zIndex: 1000,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  "& .MuiButtonBase-root, MuiChip-root, MuiChip-filled": {
                    background: "white",
                    height: "20px",
                    borderRadius: 0,
                    fontFamily: "tmwCustomFont",
                  },
                  padding: "10px 0",
                  minWidth: "250px",
                }}
                id="selectedAuthor"
                name="selectedAuthor"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderFromDateAutoselect = () => {
    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          From Date
        </span>
        <MobileDatePicker
          inputFormat="dd/MM/yyyy"
          onChange={(date) => {
            handleFilterChange("fromDate", date);
          }}
          renderInput={(inputProps) => (
            <>
              <CustomTextField
                style={{ width: "150px" }}
                // label={t(tokens.linesheets.table.windowStart)}w
                // onKeyDown={onKeyDown}
                inputProps={inputProps}
                calendar
                clearable={filters.fromDate}
                clearableClick={() => {
                  handleFilterChange("fromDate", null);
                }}
                // touched={formik.touched.inboundDelivery?.from}
                // errors={formik.errors.inboundDelivery?.from}
              />
            </>
          )}
          value={filters.fromDate}
          // maxDate={formik.values.inboundDelivery.to}
        />
      </Stack>
    );
  };

  const renderToDateAutoselect = () => {
    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px -25px -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          To Date
        </span>
        <MobileDatePicker
          inputFormat="dd/MM/yyyy"
          onChange={(date) => {
            handleFilterChange("toDate", date);
          }}
          renderInput={(inputProps) => (
            <>
              <CustomTextField
                style={{ width: "150px" }}
                // label={t(tokens.linesheets.table.windowStart)}w
                // onKeyDown={onKeyDown}
                inputProps={inputProps}
                calendar
                clearable={filters.toDate}
                clearableClick={() => {
                  handleFilterChange("toDate", null);
                }}
                // touched={formik.touched.inboundDelivery?.from}
                // errors={formik.errors.inboundDelivery?.from}
              />
            </>
          )}
          value={filters.toDate}
          // maxDate={formik.values.inboundDelivery.to}
        />
      </Stack>
    );
  };

  const renderAddNewInboundRequestButton = () => {
    return (
      <CustomButton
        label="Add New Inbound Request"
        onClick={() => {
          window.location.href = paths.dashboard.inbound.new;
        }}
      />
    );
  };

  const renderQuickSearch = () => {
    return (
      <Stack
        style={{
          flexDirection: "row",
          width: "200px",
        }}
      >
        <GridToolbarQuickFilter
          id="custom-searchbar"
          debounceMs={250}
          sx={{
            "& .MuiInput-root:before": {
              borderBottom: "none !important",
            },
            "& .MuiInput-root:after": {
              borderBottom: "none !important",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none !important",
            },
            "& .MuiSvgIcon-root": {
              cursor: "pointer",
            },
            "& .MuiInputBase-root-MuiInput-root": {
              cursor: "pointer",
            },
            "& .MuiInput-input": {
              fontFamily: "tmwCustomFont",
              paddingBottom: "2px",
              fontSize: 11,
            },
            "& .MuiInput-input::placeholder": {
              color: "#BEBEBE",
            },
            "& .MuiInput-input::-ms-input-placeholder": {
              color: "black",
            },
            overflow: "hidden",
          }}
          style={{
            fontFamily: "tmwCustomFont",
            borderRadius: "25px",
            border: "1px solid #E6E6E6",
            padding: "5px 10px",
            letterSpacing: 2,
            width: "200px",
            cursor: "pointer",
            transition: "all .2s ease-in-out",
          }}
          size="small"
          placeholder={"SEARCH"}
        />
      </Stack>
    );
  };

  const renderFiltersContainer = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 50,
          margin: "-15px 0 15px 15px",
          width: "100%",
        }}
      >
        {renderPODocumentNumberAutoselect()}
        {renderInboundShippingNumberAutoselect()}
        {renderTrackingNumberAutoselect()}
        {userGroup !== groupTypes.brand && renderBrandAutoselect()}
        {renderSeasonAutoselect()}
        {renderAuthorAutoselect()}
        {renderFromDateAutoselect()}
        {renderToDateAutoselect()}
      </Stack>
    );
  };

  const renderSecondaryButtons = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* LEFT Section */}
        <Stack style={{ flexDirection: "row", gap: 10 }}>
          {renderQuickSearch()}
        </Stack>

        {/* RIGHT Section */}
        <Stack style={{ flexDirection: "row", gap: 10 }}>
          {renderResetFiltersButton()}
          {renderAddNewInboundRequestButton()}
          {/* {renderExportButton()} */}
        </Stack>
      </Stack>
    );
  };

  // Custom toolbar for Table Header
  const customToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0 -15px -15px 0",
        }}
      >
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Stack
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "lightgrey transparent",
            }}
          >
            {renderFiltersContainer()}
          </Stack>
          <Stack
            style={{
              display: "flex",
              justifyContent: "space-around",
              minHeight: "100px",
              gap: 10,
              margin: "-15px 0 5px 0",
            }}
          >
            {renderSecondaryButtons()}
          </Stack>
        </Stack>
      </GridToolbarContainer>
    );
  };

  const renderTable = () => {
    return (
      <>
        <Container
          maxWidth={false}
          sx={{
            maxHeight: "90vh",
            width: "100%",
            scrollbarWidth: "thin",
            scrollbarColor: "lightgrey transparent",
          }}
        >
          <DataGridPro
            disableColumnFilter
            disableDensitySelector
            sx={dataGridStyle}
            rows={filters.allInboundRequests}
            columns={tableColumnsDefinition}
            experimentalFeatures={{ columnGrouping: true }}
            pagination
            slots={{
              toolbar: customToolbar,
            }}
            disableColumnSelector
            disableRowSelectionOnClick
            slotProps={{
              toolbar: {
                style: {
                  marginTop: "-75px",
                  marginBottom: "25px",
                  marginLeft: 0,
                  display: "flex",
                  flexDirection: "row-reverse",
                  width: "25%",
                },
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
              },
              pagination: {
                SelectProps: {
                  MenuProps: {
                    sx: {
                      "& .MuiMenuItem-root": {
                        textTransform: "uppercase",
                        fontSize: 11,
                        letterSpacing: 1,
                      },
                    },
                  },
                },
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Container>
      </>
    );
  };

  // Table Settings
  const tableColumnsDefinition = columnsDefinition(userGroup);

  return (
    <>
      <Seo title="Dashboard: Inbound Requests" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
          px: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {renderTable()}
      </Box>
    </>
  );
};

export default withInboundProcessGuard(Inbound);
